.m-checklist
{
    @include body;
    list-style: outside;
    list-style-type: disc;
    padding:0;
    margin-left:30px;
    &__item
    {
        margin-bottom:.25rem;
        //position: relative;
        &>ul {
            margin-top:0.5rem;
            //>li::before {
             //   background-image:url('../images/right-arrow-green.png') !important; 
            //}
        }
    }
    &__item--bold {
        font-weight:500;
    }
    /*&__item:before
    {
        content:''; 
        display:inline-block; 
        height:1em; 
        width:1em; 
        background-image:url('../images/right-arrow.png'); 
        background-size:contain; 
        background-repeat:no-repeat; 
        position: absolute;
        left:-30px;
        top:3px; 
    }
*/
}