.a-badge {
    background-color:$eyebrow;
    color:white;
    border-radius:100px;
    padding:4px 8px;
    font-size:0.75rem !important;
    font-weight:$bold !important;
    &--positive {
        background-color:$green;
    }
    &--accent {
        background-color:$eyebrow;
    }
    &--negative {
        background-color:$warning;
    }
}