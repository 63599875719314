.o-filter {
    // position: relative;
    &__group {
        border-top:solid 1px $light-gray;
        padding: 1rem 0;
    }
    &__group:first-child {
        border-top: none;
    }
    &__group-heading {
        @include heading-5
    }
    &__fixed-btn-row {
        position: absolute;
        padding:1rem;
        bottom:0px;
        right:0px;
        width:100%;
        background-color: white;
    }
}