.o-filter-manager {
    display: flex;
    flex-wrap: nowrap;
    &__btn {
        margin-right:1rem;
    }
    &__terms {
        display: flex;
        align-items: center;
    }
    &__term {
        margin-right:0.5rem;
    }
}