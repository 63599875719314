.m-share-block {
    // background-color:$lightest-gray;
    padding:1rem 0;
    @media (max-width:767px)
        {
            flex-wrap: wrap;
        }
    &__label {
        font-weight:500;
        color:$medium-gray;
        margin-right: 1rem;
        display: block;
        @media (max-width:767px)
        {
            flex-basis:100%;
        }
    }
    &__share-link {
        flex-basis:28px;
        margin: 0rem .5rem;
        display: inline-block;
    }
    &__share-link:first-of-type {
        margin-left:0;
    }
    &__icon {
        width:28px;
        height:auto;
        filter: grayscale(100%);
        opacity: 0.6;
        transition: all .5s ease-out;
    }
    &__icon:hover {
        filter: grayscale(0%);
        opacity: 1;
    }
    &__icon-wrapper{
        display: flex;
        align-items: baseline;
        // justify-content: center;
        justify-content: flex-start;
    }
}