.m-toc {
    
    position: relative;
    padding-bottom:2rem;
    
    &__item {
        margin-bottom:0.5rem;
        
    }
    &__list {
        padding-left: 16px;
    }
    &__toggle {
        position: absolute;
        top:0;
        right:16px;
        transition: all 0.2s ease-in;
        @media (min-width: 768px) { 
            display: none;
         }
    }
    &__toggle--expanded {
        transform: rotate(180deg);
        transition: all 0.2s ease-in;
    }
    &__toggle-img {
        width:32px;
        height:auto;

    }
    &__fade {
        height:100px;
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        background: rgb(245,245,245);
        background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.3) 39%, rgba(245, 245, 245, 0.9) 80%, whitesmoke 100%);
        text-align: center;
        @media (min-width: 768px) { 
            display: none;
         }
    }
    &__collapse-toggle {
        position: absolute;
        bottom:0;
        display: inline-block;
        
        
    }
    &--collapsed {
        @media (max-width: 767px) { 
            max-height:125px;
            overflow-y:hidden;
         }
    }
    &__link {
        color:$medium-gray;
        font-weight:400;
        &--active {
            color:$dark-gray;
            font-weight:600;
        }
    }
    &__link:hover {
        color:$action-color;
        // font-weight:600;
    }
}
