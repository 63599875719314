.o-masonry-section {
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: space-between;;
    &__block {
        flex-basis:calc(50% - 10px);
        @media (max-width:767px) {
            flex-basis:calc(100% - 10px)
        }
        margin-top:10px;
        margin-bottom:10px;
        transition: all .3s ease-out;
        &--one-fourth {
            flex-basis:calc(25% - 10px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 10px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 10px)
            }
        }
        &--one-third {
            flex-basis:calc(33.33% - 10px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 10px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 10px)
            }
        }
        &--two-third {
            flex-basis:calc(66.66% - 10px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 10px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 10px)
            }
        }
        &--three-fourth {
            flex-basis:calc(75% - 10px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 10px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 10px)
            }
        }
        &--full-width {
            flex-basis:100%;
            padding-right:25%;
        }
    }
    &__block:hover {
        margin-top:5px;
        margin-bottom:15px;
        // padding-top: calc(2rem - 5px);
        // padding-bottom: calc(2rem - 5px);
        -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.37);
        -moz-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.37);
        box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.37);
        // color:white;
        // background-color:$dark-blue;
        // background-color: $teal;
        // color:white;
    }
}