.m-pagination-row {
    
    display: flex;
    justify-content: center;
    &__page {
        
        margin:.5rem;
        @extend .a-button;
        @extend .a-button--small;
        &--unselected {
            @extend .a-button--outline;
        }        
        &--current {
            
        }
        &--disabled {
            @extend .a-button--outline;
            @extend .a-button--disabled;
            @extend .a-button--small;
        }
    }
    
}