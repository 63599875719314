.m-subject-highlight {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    &__content {
        margin-left:.5rem;
    }
    &__title {
        margin-bottom: 0;
    }
    &__subtitle {
        margin-bottom: 0;
    }
    &__meta {
        margin-bottom: 0;
    }
}