.a-text-box
{
    padding:.5rem;
    font-weight:300;
    border-radius:0px;
    border:solid 1px $light-gray;
    transition: all .3s ease-out;
    width: 100%;
}

.a-text-box:focus
{
    border:solid 1px $medium-gray;
    outline:0;
}