.a-resource-headline {
    &__tag {
        display: block;
        // text-transform: uppercase;
        font-size:0.9rem;
        font-weight:400;
        color:$medium-gray;
    }
    &__title {
        display: block;
        font-family: $header-font;
        font-size:1rem;
        color:$dark-gray;
        font-weight:900;
        line-height: 1.3;
    }
}

.a-resource-headline:hover {
    .a-resource-headline__tag {
       
        &--yellow {
            color:#FBAE30;
        }
        &--purple {
            color:#6C00D6;
        }
        &--red {
            color: #F0705F;
        }
        &--green {
            color:#2FAD69;
        }
        &--blue {
            color:$bright-blue;
        }
    }

}