.m-announcement {
    padding:1.5rem 1rem;
    background-color: #fbf6ef;
    border-radius:10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.15); 
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.15);
    &--gray {
        background-color:#f5f5f5;
    }
    @media (max-width:993px) {
        flex-direction: column;
        align-items: flex-start;
    }
    &__img {
        flex-basis:32px;
        width:48px;
        height:48px;
        margin-right:2rem;
        @media (max-width:993px) {
            text-align: center;
            margin-left:auto;
            margin-right:auto;
            margin-bottom:1rem;
        }
    }
    &__title {
        // color:#ff8800;
        font-family:$header-font;
        font-weight:900;
        font-size:1.25rem; 
    }
    &__message {
        padding-right:10%;
    }
    &__cta {
        background-color: $action-color;
        color:white;
        padding: .5rem;
        border-radius:6px;
        // flex-basis:20%;
        font-size:0.9rem;
        white-space: nowrap;
        padding: .5rem 1rem;
        margin-right:1rem;
        font-weight:500;
    }
}