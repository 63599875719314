.a-read-only-value
{
    @include body;
    display: block;
    color:$medium-gray;
    margin-top:0.5rem;
    &--truncated {
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
}