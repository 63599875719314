﻿@mixin transition-on-color
{
    transition: color 0.3s;
}

@mixin transition-all
{
    transition: all 0.4s;
}
@mixin shadowed-bold
{
    -webkit-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
    box-shadow: 0px 4px 75px -10px rgba(0,0,0,0.50);
}

@mixin shadowed-hard
{
    -webkit-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
    box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
}
@mixin shadowed
{
    -webkit-box-shadow: 0px 1px 20px -5px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 20px -5px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 20px -5px rgba(0,0,0,0.20);
}

@mixin underline-left() {
  content : "";
  position: absolute;
  left    : 0;
  bottom  : -7px;
  height  : 1px;
  width   : 120px;  /* or 100px */
  border-bottom: solid 4px $medium-purple;
}

@mixin underline-center() {
  content : "";
  position: absolute;
  left    : 0;
  right:0;
  margin: 0px auto;
  bottom  : 0;
  height  : 1px;
  width   : 120px;  /* or 100px */
  border-bottom: solid 4px $medium-purple;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}



@mixin block-title--quote(){
  @include pull-quote;
}

@mixin body(){
  //font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
}

@mixin body--bold(){
  font-size: 1rem;
  font-weight: 700;
}

@mixin heading-1() {
  //font-size:52px;
  font-size: 3.25rem;
  font-weight:$bold;
  @media (max-width: 993px) {
    font-size:32px;
  }
}

@mixin heading-2(){
  //font-size:36px;
  font-size:2.25rem;
  font-weight:$bold;
  @media (max-width: 993px) {
    font-size:1.75rem;
  }
}

@mixin heading-3(){
  //font-size:28px;
  font-size: 1.75rem;
  font-weight:$bold;
  @media (max-width: 993px) {
    font-size:1.5rem;
  }
}

@mixin heading-4(){
  //font-size:24px;
  font-size: 1.5rem;
  font-weight:$bold;
  @media (max-width: 993px) {
    font-size:1.25rem;
  }
}

@mixin heading-5(){
  //font-size:24px;
  //font-size:20px;
  font-size:1.25rem;
  font-weight:$bold;
  @media (max-width: 993px) {
    font-size:1rem;
  }
}

@mixin heading-6(){

  //font-size:16px;
  font-size: 1rem;
  font-weight:$bold;
}

@mixin subhead(){
  //font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
}

@mixin eyebrow(){
  //font-size:16px;
  font-size: 1rem;
  font-weight: $bold;
    color:$eyebrow;
    margin-bottom: 0;
}



@mixin eyebrow--light-blue {
    color:$icon-color;
    font-size: 1rem;
    font-weight: $bold;
}

@mixin eyebrow--white {
  @include eyebrow();
    color:#FFFFFF;
}

@mixin pull-quote() {
  color: $eyebrow;
  //font-size: 20px;
  font-size:1.25rem;
}

//don't think we need body mixin

@mixin hyperlink(){
  //font-size: 16px;
  font-size: 1rem;
  &:hover {
    text-decoration: underline;
}
}





@mixin footer-link() {
  //font-size: 14px;
  font-size: .875rem;
  font-weight: 400;
}

