.o-details-block-row
{
    @extend .row;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top:2.5rem;
    padding-bottom:5rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: space-between;
    // counter-reset:columnCount;
    &__title
    {
        @extend .col-12;
        text-align: center;
        font-size:2.5rem;
        margin-bottom:2.5rem;
        font-weight: $font-heavy;
        font-family: $header-font;
    }
    &__wrapper
    {
        @extend .col-12;
        @extend .card-deck;
        justify-content:space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        @media (max-width: 766px) { 
            flex-direction: column;
            align-items: center;
         }
    }
    &__details-block
    {
        margin-bottom:5rem;
    }
    &__details-block:nth-last-child(2):first-child, &__details-block:nth-last-child(2):first-child ~ &__details-block
    {
        // flex-basis: 400px;
        // counter-increment: columnCount;
        // margin-left:1rem;
        // margin-right:1rem;
        // flex:1 1 0;
        // flex-basis:0;
        //  @media (max-width: 1000px) { 
        //     margin-top:3rem;
        //     margin-bottom:3rem;
        //     margin-left:0rem;
        //     margin-right:0rem;
        //  }
        flex-basis: 47%;

        
    }
    &__details-block:nth-last-child(3):first-child, &__details-block:nth-last-child(3):first-child ~ &__details-block
    {
        // flex-basis: 400px;
        // counter-increment: columnCount;
        // margin-left:1rem;
        // margin-right:1rem;
        // flex:1 1 0;
        // flex-basis:0;
        //  @media (max-width: 1000px) { 
        //     margin-top:3rem;
        //     margin-bottom:3rem;
        //     margin-left:0rem;
        //     margin-right:0rem;
        //  }
        flex-basis: 30%;
        @media (max-width: 767x) { 
            flex-direction: column;
            align-items: center;
         }

        
    }
    &__details-block:first-of-type
    {
        margin-top:0rem;
        margin-left:0rem;
    }
    &__details-block:last-of-type
    {
        margin-right:0rem;
        margin-bottom:0;
    }
}