.a-label
{
    @include body;
    display: block;
    font-weight: $bold;
    margin-bottom:0;
    &--required::after {
        content: '*';
        color:$red;
    }
}