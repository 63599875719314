.a-chip {
    background-color:$eyebrow;
    color:white;
    padding:.5rem .75rem;
    border-radius:100px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    &__icon {
        height:12px;
        transition: fill 0.2s ease-out;
        fill:rgba(255,255,255,0.8);
    }
    &__remove-btn {
        background-color: transparent;
        border:none;
        padding:.25rem
    }
    &__remove-btn:hover {
        .a-chip__icon {
            fill:rgba(255,255,255,1);
        }
    }
    &__label {
        margin:.25rem .5rem .25rem .25rem;
        font-size:0.8rem;
    }
    &__category {
        color:rgba(255, 255, 255, 0.8);
    }
}