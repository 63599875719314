.m-photo-hero-slide {
    background-color:$light-gray;
    height:500px;
    border-radius: 15px;
    position: relative;
    background-size: cover;
    background-position: center center;
    &__prev {
        color: rgba(255,255,255,0.8) !important;
    }
    &__next {
        color: rgba(255,255,255,0.8) !important;
    }
    &__content {
        padding:2rem 5rem;
        @media (max-width:993px) {
            padding:2rem 3rem;
        }
        @media (max-width:776px) {
            padding:2rem 2rem;
        }
        position: absolute;
        height:100%;
        width:100%;
        top: 0px;
        left:0px;
        background: linear-gradient(180deg, rgba(32,32,32,0.1) 0%, rgba(32,32,32,0.4) 30%, rgba(32,32,32,0.65) 80%, rgba(32,32,32,0.95) 100%);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start
    }
    &__tag {
        display: inline-block;
        background-color:$bright-blue;
        padding: .25rem .75rem;
        color:rgba(255,255,255,0.8);
        margin-bottom: 1rem;
        border-radius:6px;
        font-size:1rem;
        font-weight:500;
        &--green {
            background-color:$green;
        }
    }
    &__title {
        color:white;
        font-size:2.5rem;
        
        width:70%;
        @media (max-width:993px) {
            font-size:2rem;
            width:80%;
        }
        @media (max-width:576px) {
            font-size:2rem;
            width:90%;
            margin-bottom:3rem;
        }
    }
    &__text {
        color:white;
        font-size:1.1rem;
        width:80%;
        @media (max-width:993px) {
            width:90%;
        }
        @media (max-width:576px) {
            display: none;
        }
    }
    &__btn {
        color:white;
        padding: .75rem 1.5rem;
        border-radius:100px;
        border: solid 1px white;
        display: inline-block;
        background-color:rgba(255,255,255,0);
        transition: all .2s ease-out;
    }
    &__btn:hover {
        color: white;
        background-color:rgba(255,255,255,0.2);
    }
    // width: 90%;
}


// Hacky overrides for now - should clean up at some point
.swiper-pagination-bullet {
    background-color:white !important;
}