.m-stat
{
    // @extend .col;
    text-align: left;
    &--left-aligned {
        text-align: left;
    }
    &__value
    {
        font-size:1.5rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom:.25rem;
        line-height:1;
        &--small {
            font-size:1rem;
            font-weight:600;
        }
    }
    &__label
    {
        color:$medium-gray;
        display: block;

    }
    &__icon
    {
        display: block;
        height:32px;
        width:32px;
        margin: .5rem auto;
        &--left-aligned {
            margin: .5rem auto .5rem 0;
        }
    }
    &__change {
        display: inline-block;
        background-color: $light-gray;
        color:$dark-gray;
        font-size:.9rem;
        padding:.25rem;
        border-radius:4px;
        font-weight:400;
        margin-left:.5rem;
    }
    &--increased {
        .m-stat__change {
            color: $green;
            background: rgba($green, 0.1);
        }
    }
    &--positive {
        .m-stat__value {
            color: $green;
        }
    }
    &--negative {
        .m-stat__value {
            color: $warning;
        }
    }
    &--bold {
        .m-stat__value {
            font-weight: $bold;
        }
    }
    &--decreased {
        .m-stat__change {
            color: $red;
            background: rgba($red, 0.1);
        }
    }

}