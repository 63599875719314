.o-tldr {
    border:solid 2px $light-gray;
    padding:1.5rem;
    position: relative;
    
    &__inner {
        overflow-y: hidden;
        max-height: 150px;
        transition: all 0.3s ease-out;
        &--expanded {
            max-height: 9999px;
        }
    }
    &__label {
        font-size:1rem;
        background-color: white;
        display: inline-block;
        padding:0 1rem;
        // transform: translateY(-2.25rem);
        position: absolute;
        top:-.75rem;
        left:0.5rem;
        color: $eyebrow;
        font-weight: $bold;
    }
    &__heading {
        margin-top:1rem;
    }
    &__toggle {
        position: absolute;
        bottom:8px;
        left:1.5rem;
        font-weight: 700;
    }
    &__mask {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height:150px;
        filter: blur(4px);
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 12%, rgba(255,255,255,0.8113620448179272) 59%, rgba(255,255,255,0) 85%);
    }
}