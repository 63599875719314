.o-cta-card-row
{
    @extend .row;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    &__title
    {
        @extend .col-12;
        text-align: center;
        font-size:2.5rem;
        margin-bottom:2.5rem;
        font-weight: $font-heavy;
        font-family: $header-font;
    }
    &__wrapper
    {
        @extend .col-12;
        @extend .card-deck;
        justify-content:space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        @media (max-width: 1000px) { 
            flex-direction: column;
            align-items: center;
         }
    }
    &__card
    {
        // flex-basis: 400px;
        margin-left:1rem;
        margin-right:1rem;
        flex:1 1 0;
        flex-basis:0;
         @media (max-width: 1000px) { 
            margin-top:3rem;
            margin-bottom:3rem;
            margin-left:0rem;
            margin-right:0rem;
         }
    }
    &__card:first-of-type
    {
        margin-top:0rem;
        margin-left:0rem;
    }
    &__card:last-of-type
    {
        margin-right:0rem;
    }
}