.o-illustration-hero
{
    @extend .jumbotron;
    @extend .jumbotron-fluid;
    background-size:cover;
    background-position-x: right;
    background-position-y: bottom;
    background-color:transparent;
    @if $high-contrast == true {
        background-color:$dark-gray;
    }
    background-repeat:no-repeat;
    padding-top:10rem;
    padding-bottom:15rem;
    position: relative;
    @media (max-width: 1000px) { 
        padding-top:5rem;
        padding-bottom:7.5rem;
     }
    &--short
    {
        padding-top:6.5rem;
        padding-bottom:10rem;
        @media (max-width:993px)
        {
            padding-top:4.5rem;
            padding-bottom:6rem;
        }
    }

    &__mask
    {
        // background-image:url('../images/swoop-mask.png');
        position: absolute;
        background-size:100%;
        bottom:0; 
        left:0;
        width:100%;
        // height:500px;
        background-repeat: no-repeat;
    }
    &__mask-image
    {
        @extend .img-fluid;
    }

    &__content-wrapper
    {
        @extend .container;
    }
    &__title
    {
        color:white;
        font-size: 4rem;
        margin-bottom:1.5rem;
        font-weight:$font-heavy;
        font-family: $header-font;
        @media (max-width:993px)
        {
            font-size:3rem;
        }
    }
    &__tagline
    {
        color:white;
        font-size:1.5rem;
    }
    &--centered-bg
    {
        background-position: 50% 50%;
    }
}