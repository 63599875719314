.m-tree {
    padding-left:0rem;
    list-style: none;
    &__icon {
        width:14px;
        height:14px;
        display: inline-block;
        margin-right:8px;
        fill:$dark-blue;
    }
    &__item-inner {
        display: flex;
    }
    &__item {
        list-style: none;
        border-radius:6px;
        &--selected {
            &>.m-tree__item-inner>.m-tree__link {
                background-color:rgba(0,0,0,0.05);
                font-weight:$bold;
            }
        }
        &--no-child {
            margin-left:17px;
        }
    }
    &__link {
        padding:0.25rem;
        display: inline-block;
        font-weight:400;
        cursor: pointer;
        color:$action-color;
    }
    &__link:hover {
        text-decoration: underline;
    }
    &__link:not([href]):not([tabindex]) {
        color:$action-color;
    }
    &__link:not([href]):not([tabindex]):hover {
        color:$action-color;
        text-decoration: underline;
    }
    &__nested-group {
        padding-left:19px;
    }
}