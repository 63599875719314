.o-split-hero {
 background-color: transparent;
 display: flex;
//  padding-left:15px;
//  padding-right:15px;
 justify-content: space-between;
//  max-width:1700px;
 align-items: stretch;
 margin-top:80px;
 min-height:calc(100vh - #{$wideMenuHeight});
 height:auto;
 @media (max-width:992px) {
    height:auto;
    min-height: auto;
}
@media (min-height:1000px) {
    // max-height:calc(70vh - #{$wideMenuHeight});
}
//  min-height:800px;
@media (max-width:992px) {
    flex-direction: column;
}
 &__subhead {
     color:$medium-gray;
     font-size:1.75rem;
 }
 &__title {
     font-size:2.5rem;
 }
 &__intro {
     font-size:1.25rem;
     margin-top:1rem;
     margin-bottom:2rem;
    //  white-space:pre-wrap;
 }
 &__text-wrapper {
    flex-basis: calc(70% - 40px);
    width: calc(70% - 40px);
    display:flex;
    @media (max-width:992px) {
        flex-direction:column;
        flex-basis:auto;
        width:auto;
    }
    justify-content: center;
    min-height:calc(100vh - #{$wideMenuHeight});
    height:auto;
    @media (max-width:992px) {
        height:auto;
        padding-left:15px;
        padding-right:15px;
    }
    @media (min-height:1000px) {
        // height:calc(70vh - #{$wideMenuHeight});
    }
    padding-top:20px;
    padding-bottom:100px;
 }
 &__visual-wrapper {
    flex-basis: 30%;
    width:30%;
    // min-height:calc(100vh - #{$wideMenuHeight});
    @media (max-width:992px) {
        height:400px;
        min-height: auto;
        width:100%;
    }
    @media (min-height:1000px) {
        // height:calc(70vh - #{$wideMenuHeight});
        // max-height:calc(70vh - #{$wideMenuHeight});
    }
    background-position: center center;
    background-size:cover;
    position: relative;
    
 }
 &__cta-button {
    display: inline-block;
    // background:rgba(255,255,255,0.8);
    background-color:$action-color;
    color:white;
    margin-left:5%;
    margin-right:10%;
    margin-bottom:1rem;
    border-radius:100px;
    padding:.75rem 2.5rem;
    font-weight:500;
    align-self: flex-start;
    
 }
 &__image {
     @extend .img-fluid;
     border-radius:10px;
 }
 &__text-inner {
     display: flex;
     flex-direction: column;
     justify-content: center;
     flex-grow: 1;
     margin-top:20px;
     padding-right:25px;
     width:100%;
    //  padding-left:10rem;
    //  padding-right:25rem;
 }
 &__mask {
     position: absolute;
     top:0;
     right:-3px;
     height:calc(100vh - #{$wideMenuHeight});
     @media (max-width:992px) {
        height:400px;
    } 
    @media (min-height:1000px) {
        // height:calc(70vh - #{$wideMenuHeight});
    }
     width:100px;
     background-repeat: repeat-y;
     background-size:100px auto;
     z-index:4;
 }
 &__visual-inner {
     display: flex;
     flex-direction: column;
     justify-content: flex-end;
     min-height:calc(100vh - #{$wideMenuHeight});
     height:100%;
     @media (max-width:992px) {
        height:400px !important;
        justify-content: flex-start;
        min-height: auto;
    }
    //  padding-bottom:4rem;
     position: relative;
    //  margin-left:15px;
    //  padding-right:7rem;
 }
 &__title {
    color:white;
    font-size:2.25rem;
    @media (max-width:1200px) {
        font-size:2rem;
    }
    z-index: 3;
    margin-left:5%;
    font-weight:800;
 }
 &__heading {
     font-size:2.25rem;
     font-weight:800;
     line-height:1.3;
     margin-top:0px;
     @media (max-width:992px) {
        margin-top:1rem;
    }
 }
 &__subtitle {
    font-size:1.25rem;
    @media (max-width:992px) {
        font-size:1.5rem;
    }
    color:white;
    font-weight:400;
    z-index: 3;
    margin-left:5%;
    margin-right:10%;
    margin-bottom:30px;
 }
 &__video-wrapper {
    min-height: calc(100vh - #{$wideMenuHeight});
    height:100%;
    @media (min-height:1000px) {
        // max-height:calc(70vh - #{$wideMenuHeight});
        // min-height:auto;
    }
    @media (max-width:992px) {
        height:auto;
        min-height: auto;
    }
    overflow: hidden;
    max-width:40vw;
    @media (max-width:992px) {
        max-width:100%;
    }
    position: relative;
 }
 &__video {
    min-height:calc(100vh - #{$wideMenuHeight});
    height:100%;
    @media (max-width:992px) {
        height:auto;
        min-height: auto;
    }
    @media (min-height:1000px) {
        // height:calc(70vh - #{$wideMenuHeight});
        // max-height:calc(70vh - #{$wideMenuHeight});
    }
    z-index:2;
 }
 &__video-text-overlay {
    height:calc(100vh - #{$wideMenuHeight});
    width:100%;
    @media (max-width:992px) {
        height:400px;
    }
    @media (min-height:1000px) {
        // height:calc(70vh - #{$wideMenuHeight});
        // max-height:calc(70vh - #{$wideMenuHeight});
    }
    position: absolute;
    top:0;
    left:0;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 5;
 }
 &__iframe {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
}
 &__overlay {
    position: absolute;
    width:100%;
    bottom:0;
    height:50vh;
    background: rgb(32,32,32);
    background: linear-gradient(180deg, rgba(32,32,32,0) 0%, rgba(32,32,32,0.5639299665178572) 68%, rgba(32,32,32,0.6591680617559523) 100%);
    z-index: 2;
 }
}