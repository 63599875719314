.m-resource-card {

    padding:0;
    border-radius:0px;
    
    background-color: white;
    display: block;
    
    &__inner {
        padding:1rem;
        border-left:solid 1px $light-gray;
        border-right:solid 1px $light-gray;
        border-bottom:solid 1px $light-gray;
        
    }
    &__color-bar {
        background-color:$background-blue;
        color:white;
        padding:1rem;
        border:solid 1px $background-blue;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__inner {
        min-height:300px;
    }
    
    &--fixed-height-small {
        min-height:250px;
    }
    &__tag {
        @include eyebrow;
        
        display: block;
        margin-bottom:0.5rem;
        
        
        font-weight:700;
        display: inline-block;
        margin-bottom: .5rem;
        vertical-align: text-bottom;

    }
    &__text {
        @include body;
        color:$dark-gray;
    }
    &__title {
        
        @include heading-4;
        line-height: 1.5rem;
        color:$dark-gray;
    }
    &__link {
        font-weight:500;
        color:$action-color;    
    }
    &__img {
        max-width:64px;
        width:64px;
        height:64px;
        fill: $icon-color;
        &--small {
            max-width:24px;
            width:24px;
            height:24px;
        }
    }
}

.m-color-block:hover {
    cursor: pointer;
}


