.m-site-search-form
{
    width:100%;
    position:relative;
    &__input
    {
        @include subhead;
        padding:.5rem 2rem .5rem 1rem;
        width:100%;
        border:solid 1px $light-gray;
        transition: all .3s ease-out;
        @media (max-width:993px)
        {
            font-size:1.5rem;
        }
    }
    &__input:focus
    {
        outline: none;
        border:solid 1px $medium-gray;
    }
    &__submit-btn
    {
        position: absolute;
        right:20px;
        top:9px;
        background-image: none;
        border:none;
        background-color:transparent;
    }
    &__input::placeholder
    {
        color:$medium-gray;
        font-weight:300;
        font-size:1rem;
        @media (max-width:993px)
        {
            font-size:1rem;
        }
    }
    &__submit-btn-img
    {
        height:20px !important;
        width:20px !important;
        @media (max-width:993px)
        {
            height:20px !important;
            width:20px !important;
        }
    }
}