.o-logo-cloud
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    &__item
    {
        margin:1rem 1rem;
        padding:1rem;
        align-content: center;
        
        // flex-basis:500px;
        // flex-basis: 20%;
        transition: all ease-out 0.2s;
    }
    &__item:hover
    {
        background-color:rgba(0,0,0,0.05);
    }
    &__img
    {
        height:80px !important;
    }
}