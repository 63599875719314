.o-top-menu {
    height:60px;
    background-color: $background-blue;
    display: flex;
    position: relative;
    top:0px;
    left:0px;
    width:100%;
    padding: 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    // margin-bottom:70px;
    &__links {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        list-style: none;
        align-items: center;
        margin-bottom: 0;
        border-right:none;
        // margin-right:1.25rem;
    }
    &__link-item {
        padding:.25rem 1.25rem;
        margin:0 0rem 0 0;
        line-height: 1;
        border-right:solid 1px rgba(255,255,255,0.2);
        opacity: 0.85;
        &--icon {
            border-right:none;
            padding:.25rem .5rem;
            opacity: 0.7;
        }
        // &--icon:hover {
        //     transition: all 0.2s ease-out;
        //     opacity: 1;
        // }
        &--icon:first-of-type {
            padding-left:1.25rem !important;
        }
    }
    &__link-item:hover {
        transition: all 0.2s ease-out;
        opacity: 1;
        text-decoration: underline;
    }
    &__link-item:last-of-type {
        border-right:none;
    }
    &__icon {
        height:24px !important;
        width:auto;
    }
    &__link {
        color:white;
        &--bold {
            font-weight:700;
        }
    }
    &__link:hover {
        color:white;
    }
    &__logo {
        height:32px !important;
        width: auto;
        margin-left:16px;
    }
    &__app-switcher {
        border:none;
        background-color:rgba(0,0,0,0.2);
        display:inline-flex;
        height:100%;
        margin-left:auto;
        align-items: center;
        justify-content: flex-end;
        flex-basis:175px;
        width:175px;
        padding-left:16px;
        padding-right:16px;
        transition: background-color 0.3s ease-out;

    }
    &__app-switcher:hover {
        background-color:rgba(0,0,0,0.3);
        .o-top-menu__app-switcher-label {
            color:white;
        }
        .o-top-menu__app-switcher-icon {
            fill:white;
        }
    }
    &__right {
        display: flex;
        justify-content: flex-end;
        height:100%;

    }
    &__app-switcher-label {
        color:rgba(255,255,255,0.8);
        white-space: nowrap;
        transition: color 0.3s ease-out;
    }
    &__app-switcher-icon {
        height:24px;
        fill:rgba(255,255,255,0.8);
        transition: fill 0.3s ease-out;
    }
}