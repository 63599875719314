.a-delta {
    display: inline-block;
    background-color: $light-gray;
    color:$dark-gray;
    font-size:.9rem;
    padding:.25rem;
    border-radius:4px;
    font-weight:400;
    margin-left:.5rem;
    &--increased {
        color: $green;
        background: rgba($green, 0.1);
    }
    &--decreased {
        color: $red;
        background: rgba($red, 0.1);
    }
}