.m-page-header
{
    text-align: center;
    width:100%;
    &__title
    {
        @include heading-1;
        font-family: $header-font;
        text-align: center;
        padding-bottom:0.5rem;
        font-weight: $font-heavy;
        letter-spacing: -.08rem;
    
        @media (max-width:993px)
        {
            font-size:2.25rem;
        }
        // @extend .underline-center;
        
    }
    &__subheader
    {
        text-align: center;
        margin-top:.5rem;
        padding-bottom:3.0rem;
        // @extend .underline-center;
        
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 128px auto;
        font-size:1.5rem;
        color:$medium-gray;
        @if $high-contrast == true {
            color:$dark-gray !important;
        }
        @media (max-width:993px)
        {
            font-size:2.5rem;
            font-size:1.3rem;
            line-height:1.6rem;
        }
        &--no-hr
        {
            background-image:none;
            padding-bottom:0rem;
        }
    }
}