.o-card-row
{
    @extend .row;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top:2.5rem;
    padding-bottom:5rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: nowrap;
    margin-right: -15px;
    margin-left: -15px;
    &__title
    {
        @extend .col-12;
        text-align: center;
        font-size:2.5rem;
        margin-bottom:2.5rem;
        font-weight: $font-heavy;
        font-family: $header-font;
    }
    &__wrapper
    {
        @extend .col-12;
    }
    &__deck
    {
        @extend .card-deck;
        justify-content:center;
        flex-direction: row;
        -ms-flex-flow: row nowrap;
    flex-flow: nowrap;
        @media (max-width: 1000px) { 
            flex-direction: column;
            align-items: center;
         }
    }
    &__card
    {
        // flex-basis: 500px;
        flex:0 1 500px !important;
        min-width: 0% !important;
         @media (max-width: 993px) { 
            margin-top:3rem;
            margin-bottom:3rem;
            flex-basis:auto !important;
         }
    }
    &__card:first-of-type
    {
        margin-top:0rem;
    }
}