.a-ams-img
{

    padding-left:8rem;
    padding-right:8rem;
    padding-bottom:7rem;
    @media (max-width:1300px)
    {
        padding-left:4rem;
        padding-right:4rem;
        padding-bottom:4rem;
    }
    @media (max-width:993px)
    {
        padding-left:2rem;
        padding-right:2rem;
        padding-bottom:2rem;
    }
    @media (max-width:575px)
    {
        padding-left:0rem;
        padding-right:0rem;
        padding-bottom:2rem;
    }
}