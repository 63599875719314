.o-page-section
{
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color:white;

    &--transparent {
        background-color: transparent;
    }
    &--beige
    {
        background-color:$beige;
    }
    &--light-gray
    {
        background-color:#f5f5f5;
    }
    &--teal
    {
        background-color:$teal;
        color:white;
    }
    &--dark-blue
    {
        background-color: $background-blue;
        color:white;
        a {
            color:white;
        }
        a:hover {
            color:white;
            text-decoration: underline;
        }
    }
    &--dark-gray
    {
        background-color: $dark-gray;
        color:white;
    }
    &--blue-gradient {
        background: rgb(52,119,129);
        // background: linear-gradient(315deg, rgba(52,119,129,1) 0%, rgba(0,42,93,1) 58%);
        color:white;
    }
    &--light-blue
    {
        background-color: $lightest-blue;
    }
    &--bg-img-top
    {
        background-position: center top;
        background-repeat: no-repeat;
    }
    &--bg-bottom-right
    {
        background-position: right bottom;
        background-repeat: no-repeat;
    }
    &--bg-img-cover
    {
        background-size: cover;
    }
    &--bg-img-contain
    {
        background-size: contain;
    }
    &--bg-img-center
    {
        background-position: center center;
        background-repeat: no-repeat;
    }
    &--bg-repeat-x
    {
        background-repeat: repeat-x;
    }

    &--bg-repeat-y
    {
        background-repeat: repeat-y;
    }

    &--collapsed
    {
        display: none;
    }

    &__close-svg {
        height:24px;
        width:24px;
    }

    &__collapse-toggle {
        position: absolute;
        top:50px;
        right:0px;
        cursor: pointer;
    }

    &__content_container
    {
        position:relative;
    }
    &__content-container--full-width
    {
        @extend .container-fluid;
    }
    &__content-container--fixed-width
    {
        @extend .container;
    }
    &__content-container--narrow
    {
        @extend .container;
        max-width:1000px;
    }
    &__content-container--med-wide-fixed-width
    {
        @extend .container;
        max-width:1440px;
    }
    &__content-container--wide-fixed-width
    {
        @extend .container;
        max-width:1700px;
    }
    &__content-container--white
    {
        background-color: white;
    }
    &__content-container--top-shadowed
    {
        position: relative;
    }
    &__content-container--top-shadowed:before
    {
        position: absolute;
        top: 0; left: 0%;
        width: 100%;
        height: 50vh;
        -webkit-box-shadow: 0px -16px 33px -10px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px -16px 33px -10px rgba(0,0,0,0.3);
        box-shadow: 0px -16px 33px -10px rgba(0,0,0,0.3);
        content: '';
        z-index:-1;
    }
    &__content-container--rounded {
        border-radius:10px;
    }
    &__content-container--pt-sm {
        padding-top:15px;
    }
    &__content-container--no-padding {
        padding-left:0;
        padding-right:0;
    }
    &__content-container--no-padding-left {
        padding-left:0;
    }
}

div.o-page-section:first-of-type
{

}