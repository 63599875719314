.a-search-input {

    position: relative;
    &__text-box {
        @extend .a-text-box;
    }
    &__text-box:focus {
        outline:0;
        border: solid 1px $eyebrow;
      }
    &__button {
        position: absolute;
        right: 12px;
        background-image: none;
        border: none;
        background-color: transparent;
        top: 6px;
    }
    &__img {
        height: 16px !important;
        width: 16px !important;
    }
    &__results-dropdown {
        padding:0rem;
        background: white;
        border:solid 1px $light-gray;
        position: absolute;
        top:38px;
        left:0px;
        z-index:999;
        width:100%;
        box-shadow: 0px 14px 20px -5px rgba(0,0,0,0.20);
        display: none;
    }
    &--results-shown {
        .a-search-input__results-dropdown {
            display: block;
        }
    }
    &__results {
        list-style: none;
        padding-left:0rem;
        margin-bottom: 0;
    }
    &__result {
        // border-bottom: solid 1px $light-gray;
        // margin:.25rem;
    }
    &__result:last-child {
        border-bottom: none;
    }
    &__result-btn {
        background-color: transparent;
        padding:.5rem;
        color:$action-color;


        width:100%;
        text-align: left;
    }
    &__result-btn:last-child {
        background-color: transparent;

        border:none;
        color:$action-color;

    }
    &__result-btn:hover {
        background-color: $lightest-gray;
        text-decoration: underline;
    }



}