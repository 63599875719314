.m-social-stat {
    
    &__value {
        font-size:2.25rem;
        font-weight:300;
        display: block;
        margin-bottom:0;
        line-height: 1;
    }
    &__label {
        // font-size:2.5rem;
        font-weight:300;
        display: block;
        color:$medium-gray;
        font-size:1rem;
    }
    &__delta {
        color:darken(#86DEB7, 25%);
        font-size:0.8rem;
        font-weight:500;
        display: inline-block;
        padding:.25rem;
        background-color:lighten(#86DEB7, 22%);
        border-radius:100px;
    }
    &__arrow {
        font-size:0.75rem;
        font-style: normal;
    }
    &__graph-wrapper {
        position: relative;
        width:90%;
        margin-top:.25rem;
    }
    &__caption {
        color:$medium-gray;
        font-size:0.75rem;
        width:80%;
        margin-top:0.5rem;

    }
}