.o-details-block-section
{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width:767px)
    {
        flex-direction: column;
    }
    &--vertical
    {
        flex-direction: column;
    }
    
    &__details-block
    {
        flex-basis:calc(33% - 12px);
        margin-bottom:6rem;
        @media (max-width:993px)
        {
            flex-basis:46%;
            margin-bottom:6rem;
        }
        @media (max-width:767px)
        {   
            margin-bottom:6rem;
            flex-basis:auto !important;
        }
    }
    &__details-block--4-col
    {
        flex-basis:24%;
        margin-bottom:2rem;
        @media (max-width:993px)
        {
            flex-basis:46%;
            margin-bottom:6rem;
        }
        @media (max-width:767px)
        {   
            margin-bottom:6rem;
            flex-basis:auto !important;
        }
    }
    &__details-block:nth-last-child(2):first-child, &__details-block:nth-last-child(2):first-child ~ &__details-block
    {
        flex-basis:calc(33% - 12px);
    }
    &__details-block:nth-last-child(3):first-child, &__details-block:nth-last-child(3):first-child ~ &__details-block
    {
        flex-basis:calc(33% - 12px);
        
    }
}