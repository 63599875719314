.m-filter-row {
    display: flex;
    justify-content: center;
    background-color: $lightest-gray;
    padding:1rem 1rem;
    border-radius:6px;
    @media (max-width: 1200px) {
        justify-content: flex-start;
    }
    &__item {
        margin:0 .25rem;
        white-space: nowrap;
    }
    width:100%;
    overflow-x: scroll;
    overflow-y: hidden;
}