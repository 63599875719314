.m-pull-quote
{   
    padding-left:1.5rem;
    padding-right:1.5rem;
    margin-top:3rem;
    margin-bottom: 3rem;
    padding-top: 70px;
    // background-image:url('../images/content-quote.png');
    background-position: 1.5rem top;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    border-left:none;
    &__text
    {
        font-family: $header-font;
        font-size:1.5rem;
        font-weight:700;
        color: $eyebrow;
        
    }
    &__source
    {
        font-size:1rem;
        color: $dark-gray;
        opacity: 1 !important;
    }
    &__name {
        font-weight: $bold;
        display: block;
    }
    &--teal
    {
        // background-image:url('../images/quotes-teal.png');
    }
    &--yellow
    {
        // background-image:url('../images/quotes-yellow.png');
    }
    &--purple
    {
        // background-image:url('../images/quotes-purple.png');
    }

}