.m-cta-block
{
    text-align:center;
    &__heading
    {
        font-family:$header-font;
        font-weight:$font-heavy;
        font-size:2rem;
    }
    &__text
    {
        margin-top:.5rem;
        margin-bottom:1.5rem;
    }
}