.m-illustration-card {
    padding:1rem 2rem;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size:35% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width:2000px) {
        background-size:40% auto;
    }
    @media (max-width:767px) {
        background-image:none !important;
    }
    &__title {
        font-size:1.75rem;
        font-family:$header-font;
        font-weight:900;
        width:60%;
        @media (max-width:767px) {
            width:90%;
        }
        line-height: 1.3;
        @media (min-width:2000px) {
            width:60%;
            font-size:2rem;
        }
    }
    &__excerpt {
        width:70%;
        margin-top:1rem;
        @media (min-width:2000px) {
            width:60%;
        }
        @media (max-width:767px) {
            width:90%;
        }
    }
    &__link {
        margin-top:1rem;
        font-weight:500;
        color:$dark-gray;
        display: inline-block;
    }

}