.o-card-hero
{
    @extend .jumbotron;
    @extend .jumbotron-fluid;
    background-size:cover;
    background-position-x: center;
    background-position-y: bottom;
    background-color:transparent;
    background-repeat:no-repeat;
    padding-top:10rem;
    padding-bottom:0rem;
    margin-bottom:0;
    position: relative;
    @media (max-width: 1000px) { 
        padding-top:5rem;
        padding-bottom:7.5rem;
     }

    &__mask
    {
        // background-image:url('../images/swoop-mask.png');
        position: absolute;
        background-size:100%;
        bottom:0; 
        left:0;
        width:100%;
        // height:500px;
        background-repeat: no-repeat;
    }
    &__mask-image
    {
        @extend .img-fluid;
    }

    &__content-wrapper
    {
        @extend .container;
    }
    
    &__title
    {
        color:$dark-gray;
        font-size: 2.5rem;
        margin-bottom:1.5rem;
        font-weight:$font-heavy;
        color:white;
        text-align:center;
 
    }
    &__tagline
    {
        color:$dark-gray;
        font-size:1.5rem;
        color:rgba(255,255,255,0.8);
        text-align: center;
    }
    &__centered-bg
    {
        background-position: 50% 50%;
    }
    &__cta-card-row
    {
        position: relative;
        bottom:-7rem;
    }
}