.o-page-nav {

    // background-color:#f9f9f9;
    // -webkit-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    // -moz-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    // box-shadow: 0px 10px 22px -18px rgba(0,0,0,0.3);

    width:100%;

    @media (max-width: 992px) {
        display: none;
    }
    &__inner {
        display: flex;
        padding:0rem 24px;
        align-items: center;
        min-height:60px;
        width:100%;
        position: relative;
        justify-content: space-between;

        &--expanded {
            height:100vh;
        }
    }
    &__breadcrumb {
        display: flex;
        list-style-type: none;
        list-style-image: url('');
        margin-left:0;
        padding-left:0;
        margin-bottom:0;
    }
    &__breadcrumb-link:hover {
        color:$action-color;
        text-decoration: underline;
    }
    &__breadcrumb-item {
        // font-size:1.1rem;
        @include body;
        color:$medium-gray;
        margin-right:.25rem;
        display: inline-block;
        @media (max-width: 1350px) {
            display: none;
        }
        &--active {
            color:$dark-gray;
            font-weight:400;
            @media (max-width: 1350px) {
                display: inline-block;
            }
        }
        &--active:hover {
            color:$dark-gray !important;
            font-weight:400;
        }
    }

    &__breadcrumb-item::after {
        content:'❯';
        font-size:16px;
        margin-left:.25rem;
        color:$medium-gray;
        text-decoration: none !important;
    }
    &__breadcrumb-item:hover:after {
        text-decoration: none !important;
    }
    &__breadcrumb-item--active::after {
        content:'' !important;

    }
    &__breadcrumb-link {
        color:$dark-gray;
    }

    &__breadcrumb-item--active:hover {
        color:$action-color;
        text-decoration: none;
    }
    &__primary-nav-list {
        list-style:none;
        display: flex;
        margin-bottom:0;

    }
    &__nav-item {
        margin-right:1rem;
        line-height: 1;
        padding-top:.75rem;
        padding-bottom:.75rem;
        padding:.75rem 1rem;

        &--dropdown-list {
            position: relative;
        }
        &--active {
            background-color:$lightest-blue;
            .o-navbar__dropdown-list {
                display: block !important;
            }
        }

        &--condensed {
            margin-right:.75rem;
        }
        &--primary {
            background: $action-color;
        }
    }
    &__nav-item-link {
        color:$dark-gray;
        font-size:1rem;
        font-weight:300;
        transition: border 0.3s ease-out, color 0.3s ease-out;
        border-bottom: solid 3px transparent;
        display:inline-block;
        &--current {
            border-bottom:solid 3px $action-color;
        }

    }
    &__nav-item--primary .o-page-nav__nav-item-link {
        color:white;

    }
    &__nav-item--primary:hover .o-page-nav__nav-item-link {
        color:white;
        text-decoration: underline;

    }
    &__nav-item-link:hover {
        color:$action-color;
    }

}

.o-navbar__inner--expanded {
    overflow-y: scroll;
    .o-navbar__primary-nav-list {
        display: block;
    }
}

