.m-resource-highlight {
    max-width:750px;
    &__img-wrapper {
        position: relative;
    }
    &__img-wrapper::after {
        display: block;
        content: '';
        /* 16:9 aspect ratio */
        padding-bottom: 56.25%;
        }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__tag {
        background-color: $bright-blue;
        color:white;
        position: absolute;
        bottom:0;
        left:0;
        display: inline-block;
        padding:.5rem 1rem;
        &--yellow {
            background-color:#FBAE30;
        }
        &--purple {
            background-color:#6C00D6;
        }
        &--red {
            background-color: #F0705F;
        }
        &--green {
            background-color:#2FAD69;
        }
    }
    &__title {
        font-size:1.25rem;
        margin-top:1rem !important;
        color:$dark-gray;
    }
    &__excerpt {
        font-weight: 300;
        color:$medium-gray;
    }
}

.m-resource-highlight:hover {
    .m-resource-highlight__title {
        color:$action-color;
    }
}