.m-update-card {
    background-color:$lightest-gray;
    padding:1rem 2rem 2rem 1rem;
    border-radius:10px;
    position: relative;
    display: block;
    &__meta {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }
    &__author-details {
        margin-left:.5rem;
        font-size: 0.9rem;
    }
    &__name {
        color:$dark-gray;
        margin: 0;
        font-weight:500;
        line-height: 1;
        margin-bottom:0.25rem;
    }
    &__role {
        color:$medium-gray;
        margin: 0;
        line-height: 1;
    }
    &__img {
        height:32px;
        width:32px;
        border-radius:100px;
    }
    &__title {
        font-weight:600;
        font-size:1.2rem;
        color:$dark-gray;
        font-family: $body-font;
        margin-top:1.5rem;
        margin-bottom:0.25rem;
    }
    &__message {
        font-size: 1rem;
        text-align: left;
        margin: .25rem 0 .25rem 0;
        color:$dark-gray;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        overflow-wrap: anywhere;
    }
    &__message:hover {
        color:$dark-gray;
    }
    &__subtitle {
        font-size:1rem;
        text-align: center;
        margin-top:0;
        margin-bottom: 2rem;
        color:$medium-gray;
    }
    &__timestamp {
        position: absolute;
        top:18px;
        font-size: 0.9rem;
        right:16px;
        color:
        $medium-gray;
    }
    &__link {
        font-weight:500;
        margin-top:0.5rem;
    }
}

