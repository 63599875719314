.o-full-hero
{
    @extend .jumbotron;
    @extend .jumbotron-fluid;
    // margin-left:15px;
    // margin-right:15px;
    background-size:cover;
    background-position-x: right;
    background-position-y: center;
    background-color:transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @if $high-contrast == true {
        background-color:$dark-gray;
    }
    background-repeat:no-repeat;
    // padding-top:10rem;
    // padding-bottom:10rem;
    height:35rem;
    
    position: relative;
    @media (min-width: 1000px) { 
        max-height:55vh;
     }
    @media (max-width: 1000px) { 
        padding-top:5rem;
        padding-bottom:5rem;
        height:23rem;
     }
    &__inner {
        // margin-top:80px;
        display: flex;
        
    }

    &__inner--no-nav {
        margin-top:0px;
        display: flex;
    }
    &__overlay {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background: rgb(0,0,0);
background: linear-gradient(38deg, rgba(0,0,0,0.7343312324929971) 0%, rgba(0,0,0,0.5102415966386555) 32%, rgba(0,0,0,0) 100%);
        z-index: 0;
    }
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__img {
        border-radius:500px;
        max-width:130px;
        margin-bottom:1rem;
        flex-basis:130px;
        margin-right:2rem;
        width:130px;
        height:130px;
    }
    &__content-wrapper
    {
        @extend .container;
        max-width:1440px;
        z-index: 1;
    }
    &__title
    {
        @include heading-1;
        color: #ffffff;
        margin-bottom:1.5rem;
        
    }
    &__tagline
    {
        @include eyebrow--white;
        margin-bottom:0;
    }
    &__subtitle
    {
        @include subhead;
        color:#ffffff;
        margin-bottom:0;
        max-width:60rem;
    }
    &--narrow
    {
        height:30rem;
        @media (max-width:993px)
        {
            height:auto;
        }
    }
    &--centered-bg
    {
        background-position: 50% 50%;
    }
}