.m-breadcrumbs {
    &__trail {
        display: flex;
        list-style-type: none;
        list-style-image: url('');
        margin-left:0;
        padding-left:0;
        // padding-inline-start: 0;
        margin-bottom:0;
    }

    &__breadcrumb-link:hover {
        color:$action-color;
        text-decoration: underline;
    }
    &__item {
        font-size:0.9rem;
        @include body;
        color:$medium-gray;
        margin-right:.25rem;
        display: inline-block;
        max-width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 767px) {
            display: none;
        }
        &--active {
            color:$dark-gray;
            font-weight:400;


        }
        &--active:hover {
            color:$dark-gray !important;
            font-weight:400;
        }
    }
    &__item::before {
        content:'❯';
        font-size:16px;
        margin-left:.25rem;
        color:$medium-gray;
        // text-decoration: none !important;
    }
    &__item:hover:after {
        text-decoration: none !important;
    }
    &__item:first-of-type::before {
        content:'' !important;

    }
    &__link {
        color:$medium-gray;
    }

    &__item--active:hover {
        color:$action-color;
        text-decoration: none;
    }

}




