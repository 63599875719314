.m-event-item {
    display: flex;
    background-color: $lightest-gray;
    padding:1rem 0.5rem;
    border-radius: 6px;
    justify-content: space-between;
    border-left:solid 5px $bright-blue;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.15); 
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.15);
    &--yellow {
        border-left:solid 5px #FBAE30;
    }
    &--purple {
        border-left:solid 5px #6C00D6;
    }
    &--red {
        border-left:solid 5px #F0705F;
    }
    &--green {
        border-left:solid 5px #2FAD69;
    }
    &__when {
        flex-basis:20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__month {
        display: block;
        color:$medium-gray;
        font-weight:400;
        line-height: 1.1;
    }
    &__day {
        display: block;
        color:$dark-gray;
        font-weight:800;
        line-height: 1.1;
        font-size:1.75rem;
    }
    &__time {
        display: block;
        color: $medium-gray;
        font-weight: 400;
        line-height: 1.1;
        font-size: 0.9rem;
        margin-top:.5rem;
    }
    &__details {
        flex-basis:50%;
    }
    &__title {
        color:$dark-gray;
        font-weight:800;
        line-height: 1.1;
        font-size:1rem;
        margin-bottom:0.5rem;
    }
    &__link {
        font-weight:400;
        line-height: 1.1;
        font-size:1rem;
    }
    &__category {
        color: $medium-gray;
        font-weight: 400;
        line-height: 1.1;
        font-size: 0.9rem;
    }
    &__action {
        flex-basis:25%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // border-left:solid 1px $light-gray;
        padding-left:0.5rem;
    }
}