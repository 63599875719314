.m-featured-cta-card {
    background-color:$lightest-blue;
    border-radius:10px;
    padding:2rem 1.5rem;
    &__tag {
        color:$bright-blue;
        padding-bottom:.5rem;
        display: inline-block;
    }
    &__title {
        font-size:1.5rem;
        font-family: $body-font;
    }
    &__text {

    }
    &__btn {
        background-color: $bright-blue;
        color:white;
        padding:.75rem 1.5rem;
        margin-top:.5rem;
        display: inline-block;
        border-radius: 5px;
    }
}