.o-insight-card-container
{
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top:2.5rem;
    padding-bottom:5rem;
    padding-left:4rem;
    padding-right:4rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    justify-content: space-between;
    &__title
    {
        text-align: center;
        font-size:2.5rem;
        margin-bottom:2.5rem;
        font-weight: $font-heavy;
        font-family: $header-font;
    }
    &__wrapper
    {
        justify-content:center;
        flex-direction: row;
        @media (max-width: 1000px) { 
            flex-direction: column;
            align-items: center;
         }
    }
    &__card
    {
        flex-basis: 32%;
        margin-bottom:3rem;
         @media (max-width: 993px) { 
            margin-top:3rem;
            margin-bottom:3rem;
            flex-basis:auto;
         }
    }
    &__card--wide
    {
        flex-basis: 66%;
         @media (max-width: 993px) { 
            margin-top:3rem;
            margin-bottom:3rem;
            flex-basis:auto;
         }
    }
    &__card:first-of-type
    {
        margin-top:0rem;
    }
}