.o-job-search-block
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: bottom;
    @media (max-width:993px)
    {
        flex-direction: row;
        justify-content:space-between;
        align-items: bottom;
        flex-wrap: wrap;
    }
    &__keyword-wrapper
    {
        margin-right:2rem;
        flex-basis:80%;
        @media (max-width:993px)
        {
            flex-basis:100%;
            margin-bottom:2rem;
            margin-right:0;
        }
    }
    &__keyword-label
    {
        font-weight:500;
        display:inline-block;
    }
    &__keyword-input
    {
        display: block;
        width:100%;
        padding:.5rem 1rem;
        font-weight:300;
        font-size:1.25rem;
    }
    &__distance-wrapper
    {
        flex-basis:2;
        margin-right:2rem;
        @media (max-width:993px)
        {
            flex-basis:40%;
            margin-right:0;
        }
    }
    &__distance-label
    {
        font-weight:500;
        display:inline-block;
    }
    &__distance-input
    {
        display: block;
        padding:.5rem 1rem;
        font-weight:300;
        font-size:1.25rem;
        width:100%;
        @extend .form-control;
        padding-top:8px;
        padding-bottom:8px;
        height:50px;
    }
    &__zip-wrapper
    {
        flex-basis:2;
        @media (max-width:993px)
        {
            flex-basis:55%;
        }
    }
    &__zip-label
    {
        font-weight:500;
        display:inline-block;
    }
    &__zip-input
    {
        display: block;
        padding:.5rem 1rem;
        font-weight:300;
        font-size:1.25rem;
        width:100%;
    }
    &__button-wrapper
    {
        flex-basis:2;;
    }
    &__button
    {
        margin-left:2rem;
        margin-top:1.8rem;
        color:white;
        background-color:$purple;
        font-weight: 300;
        font-size:1.25rem;
        padding:.5rem 1rem;
        border:none;
        @media (max-width:993px)
        {
            margin-left:auto;
            padding: .75rem 3rem;
            width:100%;
        }
    }
}