:root, .light-theme {
    --blue1: hsl(206, 100%, 99.2%);
    --blue2: hsl(210, 100%, 98.0%);
    --blue3: hsl(209, 100%, 96.5%);
    --blue4: hsl(210, 98.8%, 94.0%);
    --blue5: hsl(209, 95.0%, 90.1%);
    --blue6: hsl(209, 81.2%, 84.5%);
    --blue7: hsl(208, 77.5%, 76.9%);
    --blue8: hsl(206, 81.9%, 65.3%);
    --blue9: hsl(206, 100%, 50.0%);
    --blue10: hsl(208, 100%, 47.3%);
    --blue11: hsl(211, 100%, 43.2%);
    --blue12: hsl(211, 100%, 15.0%);
  }
  .dark-theme {
    --blue1: hsl(212, 35.0%, 9.2%);
    --blue2: hsl(216, 50.0%, 11.8%);
    --blue3: hsl(214, 59.4%, 15.3%);
    --blue4: hsl(214, 65.8%, 17.9%);
    --blue5: hsl(213, 71.2%, 20.2%);
    --blue6: hsl(212, 77.4%, 23.1%);
    --blue7: hsl(211, 85.1%, 27.4%);
    --blue8: hsl(211, 89.7%, 34.1%);
    --blue9: hsl(206, 100%, 50.0%);
    --blue10: hsl(209, 100%, 60.6%);
    --blue11: hsl(210, 100%, 66.1%);
    --blue12: hsl(206, 98.0%, 95.8%);
  }
  :root, .light-theme {
    --slate1: hsl(206, 30.0%, 98.8%);
    --slate2: hsl(210, 16.7%, 97.6%);
    --slate3: hsl(209, 13.3%, 95.3%);
    --slate4: hsl(209, 12.2%, 93.2%);
    --slate5: hsl(208, 11.7%, 91.1%);
    --slate6: hsl(208, 11.3%, 88.9%);
    --slate7: hsl(207, 11.1%, 85.9%);
    --slate8: hsl(205, 10.7%, 78.0%);
    --slate9: hsl(206, 6.0%, 56.1%);
    --slate10: hsl(206, 5.8%, 52.3%);
    --slate11: hsl(206, 6.0%, 43.5%);
    --slate12: hsl(206, 24.0%, 9.0%);
  }
  .dark-theme {
    --slate1: hsl(200, 7.0%, 8.8%);
    --slate2: hsl(195, 7.1%, 11.0%);
    --slate3: hsl(197, 6.8%, 13.6%);
    --slate4: hsl(198, 6.6%, 15.8%);
    --slate5: hsl(199, 6.4%, 17.9%);
    --slate6: hsl(201, 6.2%, 20.5%);
    --slate7: hsl(203, 6.0%, 24.3%);
    --slate8: hsl(207, 5.6%, 31.6%);
    --slate9: hsl(206, 6.0%, 43.9%);
    --slate10: hsl(206, 5.2%, 49.5%);
    --slate11: hsl(206, 6.0%, 63.0%);
    --slate12: hsl(210, 6.0%, 93.0%);
  }
  :root, .light-theme {
    --grass1: hsl(116, 50.0%, 98.9%);
    --grass2: hsl(120, 60.0%, 97.1%);
    --grass3: hsl(120, 53.6%, 94.8%);
    --grass4: hsl(121, 47.5%, 91.4%);
    --grass5: hsl(122, 42.6%, 86.5%);
    --grass6: hsl(124, 39.0%, 79.7%);
    --grass7: hsl(126, 37.1%, 70.2%);
    --grass8: hsl(131, 38.1%, 56.3%);
    --grass9: hsl(131, 41.0%, 46.5%);
    --grass10: hsl(132, 43.1%, 42.2%);
    --grass11: hsl(133, 50.0%, 32.5%);
    --grass12: hsl(130, 30.0%, 14.9%);
  }
  :root, .light-theme {
    --cyan1: hsl(185, 60.0%, 98.7%);
    --cyan2: hsl(185, 73.3%, 97.1%);
    --cyan3: hsl(186, 70.2%, 94.4%);
    --cyan4: hsl(186, 63.8%, 90.6%);
    --cyan5: hsl(187, 58.3%, 85.4%);
    --cyan6: hsl(188, 54.6%, 78.4%);
    --cyan7: hsl(189, 53.7%, 68.7%);
    --cyan8: hsl(189, 60.3%, 52.5%);
    --cyan9: hsl(190, 95.0%, 39.0%);
    --cyan10: hsl(191, 91.2%, 36.8%);
    --cyan11: hsl(192, 85.0%, 31.0%);
    --cyan12: hsl(192, 88.0%, 12.5%);
  }
  :root, .light-theme {
    --amber1: hsl(39, 70.0%, 99.0%);
    --amber2: hsl(40, 100%, 96.5%);
    --amber3: hsl(44, 100%, 91.7%);
    --amber4: hsl(43, 100%, 86.8%);
    --amber5: hsl(42, 100%, 81.8%);
    --amber6: hsl(38, 99.7%, 76.3%);
    --amber7: hsl(36, 86.1%, 67.1%);
    --amber8: hsl(35, 85.2%, 55.1%);
    --amber9: hsl(39, 100%, 57.0%);
    --amber10: hsl(35, 100%, 55.5%);
    --amber11: hsl(30, 100%, 34.0%);
    --amber12: hsl(20, 80.0%, 17.0%);
  }
  :root, .light-theme {
    --red1: hsl(359, 100%, 99.4%);
    --red2: hsl(359, 100%, 98.6%);
    --red3: hsl(360, 100%, 96.8%);
    --red4: hsl(360, 97.9%, 94.8%);
    --red5: hsl(360, 90.2%, 91.9%);
    --red6: hsl(360, 81.7%, 87.8%);
    --red7: hsl(359, 74.2%, 81.7%);
    --red8: hsl(359, 69.5%, 74.3%);
    --red9: hsl(358, 75.0%, 59.0%);
    --red10: hsl(358, 69.4%, 55.2%);
    --red11: hsl(358, 65.0%, 48.7%);
    --red12: hsl(354, 50.0%, 14.6%);
  }
  /** Code mirror */
  .mdxeditor .cm-editor {
      --sp-font-mono: var(--font-mono);
      --sp-font-body: var(--font-body);
      padding: var(--sp-space-4) 0;
    }
  .mdxeditor .cm-scroller {
      padding: 0 !important;
    }
  .mdxeditor .sp-wrapper {
      border: 1px solid var(--baseLine);
      border-radius: var(--radius-medium);
      overflow: hidden;
    }
  .mdxeditor .sp-layout {
      border: none;
    }
  .mdxeditor .sp-cm pre {
        white-space: break-spaces;
        word-break: break-word;
        overflow-wrap: anywhere;
        flex-shrink: 1;
      }
  /** Diff viewer */
  .mdxeditor .cm-mergeView .cm-scroller {
      font-family: var(--font-mono);
      line-height: 1.3rem;
      font-size: var(--text-xs);
    }
  /** Diff viewer */
  .mdxeditor .cm-sourceView .cm-scroller {
      font-family: var(--font-mono);
      line-height: 1.3rem;
      font-size: var(--text-xs);
    }
  .mdxeditor .cm-gutters {
      background: transparent;
      font-size: var(--text-xxs);
    }
  ._editorRoot_127fi_36 {
    --accentBase: var(--blue1);
    --accentBgSubtle: var(--blue2);
    --accentBg: var(--blue3);
    --accentBgHover: var(--blue4);
    --accentBgActive: var(--blue5);
    --accentLine: var(--blue6);
    --accentBorder: var(--blue7);
    --accentBorderHover: var(--blue8);
    --accentSolid: var(--blue9);
    --accentSolidHover: var(--blue10);
    --accentText: var(--blue11);
    --accentTextContrast: var(--blue12);
  
    --baseBase: var(--slate1);
    --baseBgSubtle: var(--slate2);
    --baseBg: var(--slate3);
    --baseBgHover: var(--slate4);
    --baseBgActive: var(--slate5);
    --baseLine: var(--slate6);
    --baseBorder: var(--slate7);
    --baseBorderHover: var(--slate8);
    --baseSolid: var(--slate9);
    --baseSolidHover: var(--slate10);
    --baseText: var(--slate11);
    --baseTextContrast: var(--slate12);
  
    --admonitionTipBg: var(--cyan4);
    --admonitionTipBorder: var(--cyan8);
    
    --admonitionInfoBg: var(--grass4);
    --admonitionInfoBorder: var(--grass8);
  
    --admonitionCautionBg: var(--amber4);
    --admonitionCautionBorder: var(--amber8);
  
    --admonitionDangerBg: var(--red4);
    --admonitionDangerBorder: var(--red8);
  
    --admonitionNoteBg: var(--slate4);
    --admonitionNoteBorder: var(--slate8);
  
    --spacing-0:0px;
    --spacing-px:1px;
    --spacing-0_5:0.125rem;
    --spacing-1:0.25rem;
    --spacing-1_5:0.375rem;
    --spacing-2:0.5rem;
    --spacing-2_5:0.625rem;
    --spacing-3:0.75rem;
    --spacing-3_5:0.875rem;
    --spacing-4:1rem;
    --spacing-5:1.25rem;
    --spacing-6:1.5rem;
    --spacing-7:1.75rem;
    --spacing-8:2rem;
    --spacing-9:2.25rem;
    --spacing-10:2.5rem;
    --spacing-11:2.75rem;
    --spacing-12:3rem;
    --spacing-14:3.5rem;
    --spacing-16:4rem;
    --spacing-20:5rem;
    --spacing-24:6rem;
    --spacing-28:7rem;
    --spacing-32:8rem;
    --spacing-36:9rem;
    --spacing-40:10rem;
    --spacing-44:11rem;
    --spacing-48:12rem;
    --spacing-52:13rem;
    --spacing-56:14rem;
    --spacing-60:15rem;
    --spacing-64:16rem;
    --spacing-72:18rem;
    --spacing-80:20rem;
    --spacing-96:24rem;
  
    --radius-none:0px;
    --radius-small: var(--spacing-0_5);
    --radius-base: var(--spacing-1);
    --radius-medium: var(--spacing-1_5);
    --radius-large: var(--spacing-2);
    --radius-extra-large: var(--spacing-3);
    --radius-full: 9999px;
  
    --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --font-body: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
    --text-base: 1rem;
    --text-sm: 0.875rem;
    --text-xs: 0.75rem;
    --text-xxs: 0.6rem;
  
    font-family: var(--font-body);
    color: var(--baseText);
  }
  
  ._editorWrapper_127fi_134 {
  }
  
  ._nestedListItem_127fi_137 {
    list-style: none;
  }
  
  ._toolbarRoot_127fi_141 {
    /* border: var(--spacing-px) solid var(--baseBorder); */
    z-index: 1;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-1);
    border-radius: var(--radius-medium);
    padding: var(--spacing-2) var(--spacing-2);
    align-items: center;
    overflow-x: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--baseBgSubtle);
    width: inherit;
  }
  
  ._toolbarRoot_127fi_141 div[role=separator] {
      margin: var(--spacing-2) var(--spacing-2);
      border-left: 1px solid var(--baseBorder);
      border-right: 1px solid var(--baseBase);
      height: var(--spacing-4);
    }
  
  ._readOnlyToolbarRoot_127fi_164 {
    pointer-events: none;
    background: var(--baseBase);
  }
  
  ._readOnlyToolbarRoot_127fi_164 > div {
      opacity: 0.5;
    }
  
  ._toolbarModeSwitch_127fi_172 {
    opacity: 1 !important;
    margin-left: auto;
    align-self: stretch;
    align-items: stretch;
    display: flex;
    border: 1px solid var(--baseBg);
    border-radius: var(--radius-medium);
    font-size: var(--text-xs);
  }
  
  ._toolbarModeSwitch_127fi_172 ._toolbarToggleItem_127fi_182 {
      padding-inline-end: var(--spacing-4);
      padding-inline-start: var(--spacing-4);
    }
  
  ._toolbarModeSwitch_127fi_172 ._toolbarToggleItem_127fi_182:active, ._toolbarModeSwitch_127fi_172 ._toolbarToggleItem_127fi_182[data-state=on] {
        background-color:var(--baseBorder);
      }
  
  ._toolbarGroupOfGroups_127fi_192 {
    display: flex;
    margin: 0 var(--spacing-1);
  }
  
  ._toolbarToggleSingleGroup_127fi_197:first-of-type ._toolbarToggleItem_127fi_182:only-child, ._toolbarToggleSingleGroup_127fi_197:only-child ._toolbarToggleItem_127fi_182:first-child, ._toolbarModeSwitch_127fi_172 ._toolbarToggleItem_127fi_182:first-child {
    border-top-left-radius: var(--radius-base);
    border-bottom-left-radius: var(--radius-base);
  }
  
  ._toolbarToggleSingleGroup_127fi_197:last-of-type ._toolbarToggleItem_127fi_182:only-child, ._toolbarToggleSingleGroup_127fi_197:only-child ._toolbarToggleItem_127fi_182:last-child, ._toolbarModeSwitch_127fi_172 ._toolbarToggleItem_127fi_182:last-child {
    border-top-right-radius: var(--radius-base);
    border-bottom-right-radius: var(--radius-base);
  }
  
  ._toolbarToggleItem_127fi_182, ._toolbarButton_127fi_211 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding: var(--spacing-1);
  }
  
  ._toolbarToggleItem_127fi_182 svg, ._toolbarButton_127fi_211 svg {
      display: block;
    }
  
  ._toolbarToggleItem_127fi_182:hover, ._toolbarButton_127fi_211:hover {
      background-color: var(--baseBgHover);
    }
  
  ._toolbarToggleItem_127fi_182:active svg, ._toolbarButton_127fi_211:active svg {
      transform: translate(1px, 1px);
    }
  
  ._toolbarToggleItem_127fi_182[data-state=on], ._toolbarButton_127fi_211[data-state=on], ._toolbarToggleItem_127fi_182:active, ._toolbarButton_127fi_211:active {
      background-color: var(--accentBgActive);
    }
  
  ._toolbarToggleItem_127fi_182[data-disabled], ._toolbarButton_127fi_211[data-disabled] {
      pointer-events: none;
      color: var(--baseBorderHover);
    }
  
  ._toolbarButton_127fi_211 {
    border-radius: var(--radius-base);
  }
  
  ._activeToolbarButton_127fi_241 {
    color: var(--accentText);
  }
  
  ._toolbarToggleSingleGroup_127fi_197 {
    white-space: nowrap;
  }
  
  ._toolbarNodeKindSelectContainer_127fi_249, ._toolbarButtonDropdownContainer_127fi_250, ._toolbarCodeBlockLanguageSelectContent_127fi_251, ._selectContainer_127fi_252
  {
    z-index: 3;
    width: var(--spacing-36);
    border-bottom-left-radius: var(--radius-base);
    border-bottom-right-radius: var(--radius-base);
    background-color: var(--baseBgHover);
    font-size: var(--text-sm);
  }
  
  ._toolbarButtonDropdownContainer_127fi_250 {
    border-top-right-radius: var(--radius-base);
  }
  
  ._toolbarNodeKindSelectTrigger_127fi_266, ._toolbarButtonSelectTrigger_127fi_267, ._selectTrigger_127fi_268 {
    border: 0;
    background-color: transparent;
    display: flex;
    color: inherit;
    align-items: center;
    width: var(--spacing-36);
    padding: var(--spacing-1);
    padding-inline-start: var(--spacing-4) ;
    padding-inline-end: var(--spacing-2);
    border-radius: var(--radius-medium);
    white-space: nowrap;
    flex-wrap: nowrap;
    font-size: var(--text-sm);
  }
  
  ._toolbarNodeKindSelectTrigger_127fi_266:hover, ._toolbarButtonSelectTrigger_127fi_267:hover, ._selectTrigger_127fi_268:hover {
      background-color: var(--baseBgHover);
    }
  
  ._toolbarNodeKindSelectTrigger_127fi_266[data-state=open], ._toolbarButtonSelectTrigger_127fi_267[data-state=open], ._selectTrigger_127fi_268[data-state=open] {
      border-bottom-right-radius: var(--radius-none);
      border-bottom-left-radius: var(--radius-none);
      background-color: var(--baseBgHover);
    }
  ._selectTrigger_127fi_268[data-placeholder] > span:first-child {
    color: var(--baseBorderHover);
  }
  
  /** used in the sandpack */
  ._toolbarButtonSelectTrigger_127fi_267 {
    width: auto;
    padding-inline-start: var(--spacing-2) ;
    padding-inline-end: var(--spacing-1);
  }
  
  ._toolbarCodeBlockLanguageSelectTrigger_127fi_304, ._toolbarCodeBlockLanguageSelectContent_127fi_251 
  {
    width: var(--spacing-48);
  }
  
  ._toolbarNodeKindSelectItem_127fi_310, ._selectItem_127fi_311
  {
    cursor: default;
    display: flex;
    padding: var(--spacing-2) var(--spacing-4);
  }
  
  ._toolbarNodeKindSelectItem_127fi_310[data-highlighted], ._selectItem_127fi_311[data-highlighted] {
      background-color: var(--baseBgSubtle);
    }
  
  ._toolbarNodeKindSelectItem_127fi_310[data-state=checked], ._selectItem_127fi_311[data-state=checked] {
      background-color: var(--accentBgActive);
    }
  
  ._toolbarNodeKindSelectItem_127fi_310[data-highlighted], ._selectItem_127fi_311[data-highlighted] {
      outline: none;
    }
  
  ._toolbarNodeKindSelectItem_127fi_310:last-child, ._selectItem_127fi_311:last-child {
      border-bottom-left-radius: var(--radius-base);
      border-bottom-right-radius: var(--radius-base);
    }
  
  ._toolbarNodeKindSelectDropdownArrow_127fi_335, ._selectDropdownArrow_127fi_336 {
    margin-left: auto;
  }
  
  ._contentEditable_127fi_340 {
    box-sizing: border-box;
    width: 100%;
    color: var(--baseTextContrast);
    padding: var(--spacing-3);
  }
  
  ._contentEditable_127fi_340:focus {
      outline: none;
    }
  
  ._sandpackWrapper_127fi_350 {
    margin-bottom:var(--spacing-5);
  }
  
  ._frontmatterWrapper_127fi_354 {
    border-radius: var(--radius-medium);
    padding: var(--spacing-3);
    background-color: var(--baseBgSubtle);
  }
  
  ._frontmatterWrapper_127fi_354[data-expanded=true] {
      margin-bottom: var(--spacing-10);
    }
  
  ._frontmatterToggleButton_127fi_364 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    display: flex;
    align-items: center;
    gap:var(--spacing-1);
    font-size: var(--text-sm);
  }
  
  ._propertyPanelTitle_127fi_372 {
    font-size: var(--text-xs);
    font-weight: 400;
    margin: 0;
    padding-top: var(--spacing-2);
    padding-left: var(--spacing-2);
  }
  
  ._propertyEditorTable_127fi_380 {
    table-layout: fixed;
    border-spacing: var(--spacing-2);
  }
  
  ._propertyEditorTable_127fi_380 th {
      text-align: left;
      font-size: var(--text-sm);
      padding: var(--spacing-2) var(--spacing-3);
    }
  
  ._propertyEditorTable_127fi_380 col:nth-child(1) {
      width: 30%;
    }
  
  ._propertyEditorTable_127fi_380 col:nth-child(2) {
      width: 70%;
    }
  
  ._propertyEditorTable_127fi_380 td:last-child ._iconButton_127fi_397 {
      margin-left: var(--spacing-4);
      margin-right: var(--spacing-4);
    }
  
  ._propertyEditorTable_127fi_380 ._readOnlyColumnCell_127fi_402 {
      padding-left: 0;
    }
  
  ._propertyEditorLabelCell_127fi_407 {
    font-weight: 400;
  }
  
  ._readOnlyColumnCell_127fi_402 {
    padding-left: 0;
  }
  
  ._buttonsFooter_127fi_415 {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-2);
  }
  
  ._propertyEditorInput_127fi_421 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    width: 100%;
    padding: var(--spacing-2) var(--spacing-3);
    border-radius: var(--radius-base);
    border: 1px solid var(--baseBorder);
    background-color: var(--baseBase);
    font-size: var(--text-sm);
  }
  
  ._iconButton_127fi_397 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    color:var(--baseText);
  }
  
  ._iconButton_127fi_397:hover {
      color: var(--accentText);
    }
  
  ._iconButton_127fi_397:disabled, ._iconButton_127fi_397:disabled:hover {
      color:var(--baseLine);
    }
  
  ._primaryButton_127fi_442, ._secondaryButton_127fi_442 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding: var(--spacing-2) var(--spacing-3);
    border: 1px solid var(--accentBorder);
    background-color: var(--accentSolidHover);
    color:var(--baseBase);
    font-size: var(--text-sm);
    border-radius: var(--radius-medium);
  }
  
  ._primaryButton_127fi_442:disabled, ._secondaryButton_127fi_442:disabled {
      background: var(--accentLine);
      border-color: var(--accentBg);
    }
  
  ._smallButton_127fi_456 {
    font-size: var(--text-xs);
    padding: var(--spacing-1) var(--spacing-2);
    border-radius: var(--radius-base);
  }
  
  ._secondaryButton_127fi_442 {
    border: 1px solid var(--baseBorder);
    background-color: var(--baseSolidHover);
    color:var(--baseBase);
  }
  
  ._dialogForm_127fi_468 {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
  }
  
  ._linkDialogEditForm_127fi_474 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-2);
  }
  
  ._linkDialogInputContainer_127fi_481 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  ._linkDialogInputWrapper_127fi_487 {
    display: flex;
    align-items: center;
    background-color: var(--baseBase);
  
    border-radius: var(--radius-base);
    border:1px solid var(--baseBorder);
  }
  
  ._linkDialogInputWrapper_127fi_487[data-visible-dropdown=true] {
      border-bottom-left-radius: var(--radius-none) ;
      border-bottom-right-radius: var(--radius-none) ;
      border-bottom-width: 0;
    }
  
  ._linkDialogInputWrapper_127fi_487 > button {
      border: 0;
      background-color: transparent;
      font-size: inherit;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      all: unset;
      box-sizing: border-box;
      cursor: default;
      padding-right: var(--spacing-2);
    }
  
  ._linkDialogInput_127fi_481 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    width: 20rem;
    padding: var(--spacing-2) var(--spacing-3);
    font-size: var(--text-sm);
  }
  
  ._linkDialogInput_127fi_481::-moz-placeholder {
      color: var(--baseBorder);
    }
  
  ._linkDialogInput_127fi_481::placeholder {
      color: var(--baseBorder);
    }
  
  ._linkDialogAutocompleteContainer_127fi_517 {
    position: relative;
  }
  
  ._linkDialogAutocompleteContainer_127fi_517 ul {
      all: unset;
      box-sizing: border-box;
      position: absolute;
      font-size: var(--text-sm);
      width: 100%;
      display: none;
      border-bottom-left-radius: var(--radius-medium) ;
      border-bottom-right-radius: var(--radius-medium) ;
      max-height: var(--spacing-48);
      overflow-x: hidden;
      overflow-y: auto;
      border: 1px solid var(--baseBorder);
      border-top-width: 0;
      background-color: var(--baseBase);
    }
  
  ._linkDialogAutocompleteContainer_127fi_517 ul[data-visible=true] {
        display: block;
      }
  
  ._linkDialogAutocompleteContainer_127fi_517 ul li {
        padding: var(--spacing-2) var(--spacing-3);
        white-space: nowrap;
        margin-bottom: var(--spacing-1);
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
  
  ._linkDialogAutocompleteContainer_127fi_517 ul li[data-selected=true] {
          background-color: var(--baseBgSubtle);
        }
  
  ._linkDialogAutocompleteContainer_127fi_517 ul li[data-highlighted=true] {
          background-color: var(--baseBgHover);
        }
  
  ._linkDialogAutocompleteContainer_127fi_517 ul li:last-of-type {
          border-bottom-left-radius: var(--radius-medium) ;
          border-bottom-right-radius: var(--radius-medium) ;
        }
  
  ._linkDialogAnchor_127fi_562 {
    position: fixed;
    background-color: highlight;
    z-index: -1;
  }
  
  ._linkDialogAnchor_127fi_562[data-visible=true] {
      visibility: visible;
    }
  
  ._linkDialogAnchor_127fi_562[data-visible=false] {
      visibility: hidden;
    }
  
  ._linkDialogPopoverContent_127fi_576, ._tableColumnEditorPopoverContent_127fi_576, ._dialogContent_127fi_576 {
    filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
    display: flex;
    align-items: center;
    gap: var(--spacing-0_5);
    border-radius:var(--radius-medium);
    border: 1px solid var(--baseBorder);
    background-color: var(--baseBgSubtle);
    padding:var(--spacing-2) var(--spacing-2);
    font-size: var(--text-sm);
  }
  
  ._largeDialogContent_127fi_588 {
    filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
    gap: var(--spacing-0_5);
    border-radius:var(--radius-medium);
    border: 1px solid var(--baseBorder);
    background-color: var(--baseBgSubtle);
    padding:var(--spacing-4);
    font-size: var(--text-sm);
  }
  
  ._dialogTitle_127fi_598 {
    font-size: var(--text-base);
    font-weight: 600;
    padding-left: var(--spacing-2);
  }
  
  ._dialogCloseButton_127fi_604 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  ._popoverContent_127fi_611 {
    filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
    display: flex;
    align-items: center;
    gap: var(--spacing-0_5);
    border-radius:var(--radius-medium);
    background-color: var(--baseBgSubtle);
    padding:var(--spacing-2) var(--spacing-2);
    font-size: var(--text-sm);
    z-index: 1;
  }
  
  ._popoverArrow_127fi_623 {
    fill: var(--baseBgSubtle);
  }
  
  
  
  ._linkDialogPreviewAnchor_127fi_629 {
    margin-right: var(--spacing-3);
    display: flex;
    align-items: flex-start;
    color: var(--accentText);
    text-decoration: none;
    border: 1px solid transparent;
  }
  
  
  
  ._linkDialogPreviewAnchor_127fi_629 svg {
      width: var(--spacing-5);
      height: var(--spacing-5);
      margin-left: var(--spacing-2);
    }
  
  
  
  ._linkDialogPreviewAnchor_127fi_629 span {
      max-width: 14rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  
  ._tooltipContent_127fi_651 {
    z-index: 2;
    position: relative;
    border-radius: var(--radius-medium);
    padding: var(--spacing-1) var(--spacing-2);
    font-size: var(--text-xs);
    background-color: var(--baseText);
    color: var(--baseBase);
  }
  
  ._tooltipContent_127fi_651 svg {
      fill: var(--baseText);
    }
  
  ._actionButton_127fi_665 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding: var(--spacing-2);
    padding: var(--spacing-1) var(--spacing-1);
    border-radius: var(--radius-medium);
  }
  
  ._actionButton_127fi_665 svg {
      display: block;
    }
  
  ._actionButton_127fi_665:hover {
      background-color: var(--baseBgHover);
    }
  
  ._actionButton_127fi_665:active svg {
      transform: translate(1px, 1px);
    }
  
  ._actionButton_127fi_665[data-state=on], ._actionButton_127fi_665:active {
      background-color: var(--accentBgActive);
    }
  
  ._primaryActionButton_127fi_671 {
    background-color: var(--accentSolid);
    color:var(--baseBase);
  }
  
  ._primaryActionButton_127fi_671:hover {
      background-color: var(--accentSolidHover);
      color:var(--baseBase);
    }
  
  ._tableEditor_127fi_680 {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
  
  ._tableEditor_127fi_680 thead > tr > th {
      text-align: right;
    }
  
  ._tableEditor_127fi_680 > tbody > tr > td:not(._toolCell_127fi_691) {
      border: 1px solid var(--baseBorder);
      padding: var(--spacing-1) var(--spacing-2);
      white-space: normal;
    }
  
  ._tableEditor_127fi_680 > tbody > tr > td:not(._toolCell_127fi_691) > div {
        outline: none;
      }
  
  ._tableEditor_127fi_680 > tbody > tr > td:not(._toolCell_127fi_691) > div > p {
          margin: 0;
        }
  
  ._tableEditor_127fi_680 > tbody > tr > td[data-active=true]:not(._toolCell_127fi_691) {
        outline: solid 2px var(--accentSolid)
      }
  
  ._tableEditor_127fi_680 ._tableColumnEditorTrigger_127fi_708, ._tableEditor_127fi_680 ._tableRowEditorTrigger_127fi_708, ._tableEditor_127fi_680 ._addRowButton_127fi_708, ._tableEditor_127fi_680 ._addColumnButton_127fi_708, ._tableEditor_127fi_680 ._iconButton_127fi_397 {
      opacity: .2;
    }
  
  ._tableEditor_127fi_680:hover ._tableColumnEditorTrigger_127fi_708, ._tableEditor_127fi_680:hover ._tableRowEditorTrigger_127fi_708, ._tableEditor_127fi_680:hover ._addRowButton_127fi_708, ._tableEditor_127fi_680:hover ._addColumnButton_127fi_708, ._tableEditor_127fi_680:hover ._iconButton_127fi_397 {
        opacity: .5;
      }
  
  ._tableEditor_127fi_680:hover ._tableColumnEditorTrigger_127fi_708:hover, ._tableEditor_127fi_680:hover ._tableRowEditorTrigger_127fi_708:hover, ._tableEditor_127fi_680:hover ._addRowButton_127fi_708:hover, ._tableEditor_127fi_680:hover ._addColumnButton_127fi_708:hover, ._tableEditor_127fi_680:hover ._iconButton_127fi_397:hover {
          opacity: 1;
        }
  
  ._toolCell_127fi_691 {
    text-align: right;
  }
  
  ._toolCell_127fi_691 button {
      margin: auto;
      display: block;
    }
  
  ._tableColumnEditorTrigger_127fi_708 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding: var(--spacing-2);
    padding: var(--spacing-1);
    border-radius: var(--radius-full);
    opacity: 0.2;
  }
  
  ._tableColumnEditorTrigger_127fi_708 svg {
      display: block;
    }
  
  ._tableColumnEditorTrigger_127fi_708:hover {
      background-color: var(--baseBgHover);
    }
  
  ._tableColumnEditorTrigger_127fi_708:active svg {
      transform: translate(1px, 1px);
    }
  
  ._tableColumnEditorTrigger_127fi_708[data-state=on], ._tableColumnEditorTrigger_127fi_708:active {
      background-color: var(--accentBgActive);
    }
  
  ._tableColumnEditorTrigger_127fi_708[data-active=true] {
      opacity: 1;
    }
  
  ._tableColumnEditorToolbar_127fi_742 {
    display: flex;
  }
  
  ._tableColumnEditorToolbar_127fi_742 > button {
      border: 0;
      background-color: transparent;
      font-size: inherit;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      all: unset;
      box-sizing: border-box;
      cursor: default;
      padding: var(--spacing-2);
    }
  
  ._tableColumnEditorToolbar_127fi_742 > button svg {
      display: block;
    }
  
  ._tableColumnEditorToolbar_127fi_742 > button:hover {
      background-color: var(--baseBgHover);
    }
  
  ._tableColumnEditorToolbar_127fi_742 > button:active svg {
      transform: translate(1px, 1px);
    }
  
  ._tableColumnEditorToolbar_127fi_742 > button[data-state=on], ._tableColumnEditorToolbar_127fi_742 > button:active {
      background-color: var(--accentBgActive);
    }
  
  ._tableColumnEditorToolbar_127fi_742 [role=separator] {
      margin-left: var(--spacing-1);
      margin-right: var(--spacing-1);
    }
  
  
  ._toggleGroupRoot_127fi_754 {
    display: inline-flex;
  }
  
  
  ._toggleGroupRoot_127fi_754 button {
      border: 0;
      background-color: transparent;
      font-size: inherit;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      all: unset;
      box-sizing: border-box;
      cursor: default;
      padding: var(--spacing-2);
    }
  
  
  ._toggleGroupRoot_127fi_754 button svg {
      display: block;
    }
  
  
  ._toggleGroupRoot_127fi_754 button:hover {
      background-color: var(--baseBgHover);
    }
  
  
  ._toggleGroupRoot_127fi_754 button:active svg {
      transform: translate(1px, 1px);
    }
  
  
  ._toggleGroupRoot_127fi_754 button[data-state=on], ._toggleGroupRoot_127fi_754 button:active {
      background-color: var(--accentBgActive);
    }
  
  
  ._toggleGroupRoot_127fi_754 button:first-child {
        border-top-left-radius: var(--radius-base);
        border-bottom-left-radius: var(--radius-base);
      }
  
  
  ._toggleGroupRoot_127fi_754 button:last-child {
        border-top-right-radius: var(--radius-base);
        border-bottom-right-radius: var(--radius-base);
      }
  
  ._tableToolsColumn_127fi_771 {
    width: 3rem;
  }
  
  ._tableToolsColumn_127fi_771 button {
      margin: auto;
      display: block;
    }
  
  ._leftAlignedCell_127fi_779 {
    text-align: left;
  }
  
  ._rightAlignedCell_127fi_783 {
    text-align: right;
  }
  
  ._centeredCell_127fi_787 {
    text-align: center;
  }
  
  ._addColumnButton_127fi_708, ._addRowButton_127fi_708 {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding: var(--spacing-2);
    background-color: var(--baseBase);
  
    display: flex;
    align-items: center;
    border-radius: var(--radius-medium);
  }
  
  ._addColumnButton_127fi_708 svg, ._addRowButton_127fi_708 svg {
      display: block;
    }
  
  ._addColumnButton_127fi_708:hover, ._addRowButton_127fi_708:hover {
      background-color: var(--baseBgHover);
    }
  
  ._addColumnButton_127fi_708:active svg, ._addRowButton_127fi_708:active svg {
      transform: translate(1px, 1px);
    }
  
  ._addColumnButton_127fi_708[data-state=on], ._addRowButton_127fi_708[data-state=on], ._addColumnButton_127fi_708:active, ._addRowButton_127fi_708:active {
      background-color: var(--accentBgActive);
    }
  
  ._addColumnButton_127fi_708 svg, ._addRowButton_127fi_708 svg {
      margin: auto;
    }
  
  ._addRowButton_127fi_708 {
    width: 100%;
    margin-top: var(--spacing-2);
    box-sizing: border-box;
  }
  
  ._addColumnButton_127fi_708 {
    margin-left: var(--spacing-2);
    height: 100%;
  }
  
  /** Dialog */
  ._dialogOverlay_127fi_815 {
    position: fixed;
    inset: 0;
    animation: _overlayShow_127fi_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: var(--baseBase);
    z-index: 51;
    opacity: 0.5;
  }
  
  ._dialogContent_127fi_576, ._largeDialogContent_127fi_588 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: _contentShow_127fi_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 52;
  }
  
  ._dialogContent_127fi_576:focus, ._largeDialogContent_127fi_588:focus {
    outline: none;
  }
  
  
  @keyframes _overlayShow_127fi_1 {
    from {
      opacity: 0;
    }
    to {
      opacity: .5;
    }
  }
  
  @keyframes _contentShow_127fi_1 {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  ._focusedImage_127fi_858 {
    outline: highlight solid 2px;
  }
  
  ._imageWrapper_127fi_862 {
    display: inline-block;
    position: relative;
  }
  
  ._imageWrapper_127fi_862[draggable=true] {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab;
  }
  
  ._inlineEditor_127fi_874 {
    display: inline-flex;
    border-radius: var(--radius-medium);
    padding: var(--spacing-1);
    gap: var(--spacing-2);
    align-items: center;
    background: var(--baseBg);
  }
  
  ._blockEditor_127fi_883 {
    display: flex;
    justify-content: stretch;
    border-radius: var(--radius-medium);
    padding: var(--spacing-2);
    gap: var(--spacing-2);
    align-items: center;
    background: var(--baseBg);
  }
  
  ._blockEditor_127fi_883 ._nestedEditor_127fi_892 {
      flex-grow: 1;
    }
  
  ._nestedEditor_127fi_892 {
    background: white;
    padding: var(--spacing-1) var(--spacing-2);
    border-radius: var(--radius-medium);
  }
  
  ._nestedEditor_127fi_892 > p {
      margin: 0;
    }
  
  ._nestedEditor_127fi_892:focus {
      outline: none;
    }
  
  ._genericComponentName_127fi_909 {
    font-size: var(--text-sm);
    color: var(--baseText);
    padding-right: var(--spacing-2);
  }
  
  ._diffSourceToggle_127fi_915 {
    border: 1px solid var(--baseBorder);
    border-radius: var(--radius-medium);
    display: flex;
  }
  
  ._diffSourceToggle_127fi_915 ._toolbarToggleItem_127fi_182 {
      padding: 0;
    }
  
  ._diffSourceToggle_127fi_915 ._toolbarToggleItem_127fi_182 > span {
        display: block;
        padding: var(--spacing-2) var(--spacing-3);
      }
  
  ._selectWithLabel_127fi_929 {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
    margin-left: var(--spacing-2);
  }
  
  ._selectWithLabel_127fi_929 > label {
      font-size: var(--text-sm);
    }
  
  ._selectWithLabel_127fi_929 ._selectTrigger_127fi_268 {
      border: 1px solid var(--baseBorder);
    }
  
  ._toolbarTitleMode_127fi_943 {
    font-size: var(--text-sm);
    margin-left: var(--spacing-2);
  }
  
  
  ._imageControlWrapperResizing_127fi_949 {
    touch-action: none;
  }
  
  ._imageResizer_127fi_953 {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: var(--accentText);
    border: 1px solid var(--baseBg);
  }
  
  ._imageResizer_127fi_953._imageResizerN_127fi_962 {
    top: -6px;
    left: 48%;
    cursor: n-resize;
  }
  
  ._imageResizer_127fi_953._imageResizerNe_127fi_968 {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
  }
  
  ._imageResizer_127fi_953._imageResizerE_127fi_974 {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
  }
  
  ._imageResizer_127fi_953._imageResizerSe_127fi_980 {
    bottom: -2px;
    right: -6px;
    cursor: nwse-resize;
  }
  
  ._imageResizer_127fi_953._imageResizerS_127fi_980 {
    bottom: -2px;
    left: 48%;
    cursor: s-resize;
  }
  
  ._imageResizer_127fi_953._imageResizerSw_127fi_992 {
    bottom: -2px;
    left: -6px;
    cursor: sw-resize;
  }
  
  ._imageResizer_127fi_953._imageResizerW_127fi_998 {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
  }
  
  ._imageResizer_127fi_953._imageResizerNw_127fi_1004 {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
  }
  
  ._placeholder_127fi_1010 {
      color: var(--baseSolid);
      overflow: hidden;
      position: absolute;
      top: 0;
      padding: var(--spacing-3);
      text-overflow: ellipsis;
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
      white-space: nowrap;
      display: inline-block;
      pointer-events: none;
  }
    
  ._rootContentEditableWrapper_127fi_1023 {
    position: relative;
  }
  :root, ._light-theme_13nbk_1 {
    --blue1: hsl(206, 100%, 99.2%);
    --blue2: hsl(210, 100%, 98.0%);
    --blue3: hsl(209, 100%, 96.5%);
    --blue4: hsl(210, 98.8%, 94.0%);
    --blue5: hsl(209, 95.0%, 90.1%);
    --blue6: hsl(209, 81.2%, 84.5%);
    --blue7: hsl(208, 77.5%, 76.9%);
    --blue8: hsl(206, 81.9%, 65.3%);
    --blue9: hsl(206, 100%, 50.0%);
    --blue10: hsl(208, 100%, 47.3%);
    --blue11: hsl(211, 100%, 43.2%);
    --blue12: hsl(211, 100%, 15.0%);
  }
  ._dark-theme_13nbk_1 {
    --blue1: hsl(212, 35.0%, 9.2%);
    --blue2: hsl(216, 50.0%, 11.8%);
    --blue3: hsl(214, 59.4%, 15.3%);
    --blue4: hsl(214, 65.8%, 17.9%);
    --blue5: hsl(213, 71.2%, 20.2%);
    --blue6: hsl(212, 77.4%, 23.1%);
    --blue7: hsl(211, 85.1%, 27.4%);
    --blue8: hsl(211, 89.7%, 34.1%);
    --blue9: hsl(206, 100%, 50.0%);
    --blue10: hsl(209, 100%, 60.6%);
    --blue11: hsl(210, 100%, 66.1%);
    --blue12: hsl(206, 98.0%, 95.8%);
  }
  :root, ._light-theme_13nbk_1 {
    --slate1: hsl(206, 30.0%, 98.8%);
    --slate2: hsl(210, 16.7%, 97.6%);
    --slate3: hsl(209, 13.3%, 95.3%);
    --slate4: hsl(209, 12.2%, 93.2%);
    --slate5: hsl(208, 11.7%, 91.1%);
    --slate6: hsl(208, 11.3%, 88.9%);
    --slate7: hsl(207, 11.1%, 85.9%);
    --slate8: hsl(205, 10.7%, 78.0%);
    --slate9: hsl(206, 6.0%, 56.1%);
    --slate10: hsl(206, 5.8%, 52.3%);
    --slate11: hsl(206, 6.0%, 43.5%);
    --slate12: hsl(206, 24.0%, 9.0%);
  }
  ._dark-theme_13nbk_1 {
    --slate1: hsl(200, 7.0%, 8.8%);
    --slate2: hsl(195, 7.1%, 11.0%);
    --slate3: hsl(197, 6.8%, 13.6%);
    --slate4: hsl(198, 6.6%, 15.8%);
    --slate5: hsl(199, 6.4%, 17.9%);
    --slate6: hsl(201, 6.2%, 20.5%);
    --slate7: hsl(203, 6.0%, 24.3%);
    --slate8: hsl(207, 5.6%, 31.6%);
    --slate9: hsl(206, 6.0%, 43.9%);
    --slate10: hsl(206, 5.2%, 49.5%);
    --slate11: hsl(206, 6.0%, 63.0%);
    --slate12: hsl(210, 6.0%, 93.0%);
  }
  :root, ._light-theme_13nbk_1 {
    --grass1: hsl(116, 50.0%, 98.9%);
    --grass2: hsl(120, 60.0%, 97.1%);
    --grass3: hsl(120, 53.6%, 94.8%);
    --grass4: hsl(121, 47.5%, 91.4%);
    --grass5: hsl(122, 42.6%, 86.5%);
    --grass6: hsl(124, 39.0%, 79.7%);
    --grass7: hsl(126, 37.1%, 70.2%);
    --grass8: hsl(131, 38.1%, 56.3%);
    --grass9: hsl(131, 41.0%, 46.5%);
    --grass10: hsl(132, 43.1%, 42.2%);
    --grass11: hsl(133, 50.0%, 32.5%);
    --grass12: hsl(130, 30.0%, 14.9%);
  }
  :root, ._light-theme_13nbk_1 {
    --cyan1: hsl(185, 60.0%, 98.7%);
    --cyan2: hsl(185, 73.3%, 97.1%);
    --cyan3: hsl(186, 70.2%, 94.4%);
    --cyan4: hsl(186, 63.8%, 90.6%);
    --cyan5: hsl(187, 58.3%, 85.4%);
    --cyan6: hsl(188, 54.6%, 78.4%);
    --cyan7: hsl(189, 53.7%, 68.7%);
    --cyan8: hsl(189, 60.3%, 52.5%);
    --cyan9: hsl(190, 95.0%, 39.0%);
    --cyan10: hsl(191, 91.2%, 36.8%);
    --cyan11: hsl(192, 85.0%, 31.0%);
    --cyan12: hsl(192, 88.0%, 12.5%);
  }
  :root, ._light-theme_13nbk_1 {
    --amber1: hsl(39, 70.0%, 99.0%);
    --amber2: hsl(40, 100%, 96.5%);
    --amber3: hsl(44, 100%, 91.7%);
    --amber4: hsl(43, 100%, 86.8%);
    --amber5: hsl(42, 100%, 81.8%);
    --amber6: hsl(38, 99.7%, 76.3%);
    --amber7: hsl(36, 86.1%, 67.1%);
    --amber8: hsl(35, 85.2%, 55.1%);
    --amber9: hsl(39, 100%, 57.0%);
    --amber10: hsl(35, 100%, 55.5%);
    --amber11: hsl(30, 100%, 34.0%);
    --amber12: hsl(20, 80.0%, 17.0%);
  }
  :root, ._light-theme_13nbk_1 {
    --red1: hsl(359, 100%, 99.4%);
    --red2: hsl(359, 100%, 98.6%);
    --red3: hsl(360, 100%, 96.8%);
    --red4: hsl(360, 97.9%, 94.8%);
    --red5: hsl(360, 90.2%, 91.9%);
    --red6: hsl(360, 81.7%, 87.8%);
    --red7: hsl(359, 74.2%, 81.7%);
    --red8: hsl(359, 69.5%, 74.3%);
    --red9: hsl(358, 75.0%, 59.0%);
    --red10: hsl(358, 69.4%, 55.2%);
    --red11: hsl(358, 65.0%, 48.7%);
    --red12: hsl(354, 50.0%, 14.6%);
  }
  ._bold_13nbk_10 {
    font-weight: bold;
  }
  ._italic_13nbk_14 {
    font-style: italic;
  }
  ._underline_13nbk_18 {
    text-decoration: underline;
  }
  ._bold_13nbk_10 {
      font-weight: 700
  }
  ._italic_13nbk_14 {
      font-style: italic
  }
  ._underline_13nbk_18 {
      text-decoration: underline
  }
  ._strikethrough_13nbk_34 {
      text-decoration: line-through
  }
  ._underlineStrikethrough_13nbk_38 {
      text-decoration: underline line-through
  }
  ._subscript_13nbk_42 {
      font-size: .8em;
      vertical-align: sub!important
  }
  ._superscript_13nbk_47 {
      font-size: .8em;
      vertical-align: super
  }
  ._code_13nbk_52 {
      background-color: var(--baseBg);
      padding: 1px .25rem;
      font-family: var(--font-mono);
      font-size: 94%
  }
  ._nestedListItem_13nbk_59 {
    list-style:none;
  }
  ._admonitionDanger_13nbk_63, ._admonitionInfo_13nbk_63, ._admonitionNote_13nbk_63, ._admonitionTip_13nbk_63, ._admonitionCaution_13nbk_63 {
    padding: var(--spacing-2);
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    border-left: 3px solid var(--admonitionBorder);
    background-color: var(--admonitionBg);
  }
  ._admonitionInfo_13nbk_63 {
    --admonitionBorder: var(--admonitionInfoBorder);
    --admonitionBg: var(--admonitionInfoBg);
  }
  ._admonitionTip_13nbk_63 {
    --admonitionBorder: var(--admonitionTipBorder);
    --admonitionBg: var(--admonitionTipBg);
  }
  ._admonitionCaution_13nbk_63 {
    --admonitionBorder: var(--admonitionCautionBorder);
    --admonitionBg: var(--admonitionCautionBg);
  }
  ._admonitionDanger_13nbk_63 {
    --admonitionBorder: var(--admonitionDangerBorder);
    --admonitionBg: var(--admonitionDangerBg);
  }
  ._admonitionNote_13nbk_63 {
    --admonitionBorder: var(--admonitionNoteBorder);
    --admonitionBg: var(--admonitionNoteBg);
  }
  