.m-byline {
    display: flex;
    align-items: center;
    // margin-bottom: 1rem;
    &__author-details {
        margin-left:.5rem;
        font-size: 0.9rem;
        line-height:1.5;
        &--large {
            font-size:1.2rem;
        }
    }
    &__name {
        margin: 0;
        line-height: 1;
        margin-bottom:0.25rem;
    }
    &__role {
        margin: 0;
        line-height: 1;
    }
    &__img {
        height:24px;
        width:24px;
        margin:8px 8px 8px 0;
        border-radius:100px;
        &--large {
            height:64px;
            width:64px;
        }
    }
}