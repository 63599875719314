.a-icon-link
{
    display: flex;
    flex-direction: row;
    font-size:1rem;
    align-items: center;
    cursor: pointer;
    padding:.5rem;
    border-radius:5px;
    &__icon
    {
        margin-right:.5rem;
        height:16px !important;
        flex-basis:16px;
        fill:$action-color;
        &--large {
            height:32px !important;
            flex-basis:32px;
        }
    }
    &__text
    {
        @include hyperlink;
        color:$action-color;
        font-weight:400;
        margin-right:.5rem;
    }
    &--btn {
        background-color:rgba(0,0,0,0.08);
        color:$action-color !important;
        border:none;
        display:inline-block;
        border-radius: 6px;
        padding:0.5rem 1rem;
        transition: all .2s ease-out;
    }
    &--btn:hover {
        background-color:rgba(0,0,0,0.1);
        
        color:$action-color !important;
        border:none;
    }
    &--active {
        background-color:rgba(0, 121, 131, 0.15);
    }
    
}

