.o-match-result {
    border-bottom:solid 1px $light-gray;
    padding: 16px 8px;
    color:$medium-gray;
    display: block;
    transition: all 0.3s ease-in;
    &__label {
        @extend .a-eyebrow;
        margin-bottom:8px;
    }
    &__term {
        display: inline-block;
        padding:0 4px;
        background-color:rgba(0,121,131,0.2);
        color:$eyebrow;
        font-weight:$bold;
        border-radius: 4px;
        &--positive {
            color:$green;
            background-color:rgba(0,131,21,0.2);
        }
        &--negative {
            color:$warning;
            background-color: rgba(168,30,0,0.2)
        }
    }
}

.o-match-result:first-of-type {
    border-top:solid 1px $light-gray;
}

.o-match-result:hover {
    color:$dark-gray;
    background-color: $lightest-gray;
}