.a-collapse-toggle
{
    display: inline-flex;
    flex-direction: row;
    font-size:1rem;
    align-items: center;
    cursor: pointer;
    border:none;
    background-color: transparent;
    &__icon-vertical {
        transition: all 0.1s ease-out;
    }
    &--expanded {
        .a-collapse-toggle__icon-vertical {
            opacity: 0;
        }
    }
    &__svg-icon
    {
        margin-right:2px;
        height:16px !important;
        flex-basis:16px;
        fill:$action-color;
        &--medium {
            height:24px !important;
            flex-basis:24px;
        }
        &--large {
            height:28px !important;
            flex-basis:28px;
        }
    }
    &__text
    {
        @include hyperlink;
        color:$action-color;
        font-weight:400;
        margin-left:.5rem;
    }


}

.a-collapse-toggle
{

    &__text:hover
    {
        text-decoration: underline;
        color:$action-color;
        font-weight:500;
    }

}