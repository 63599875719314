.m-color-block {
    background-color:$lightest-gray;
    padding:2rem 2rem;
    border-radius:0px;
    border:solid 1px rgba(0, 42, 93, 0.3);
    border:solid 1px $light-gray;
    &__color-bar {
        background-color:$dark-blue;
        color:white;
    }
    &--green {
        background-color: #2FAD69;
        color:white !important;
    }
    &--blue {
        background-color: $bright-blue; 
        color:white !important;
    }
    &--light-blue {
        background-color: $lightest-blue;
    }
    &--white {
        background-color: white;;
    }
    &--fixed-height {
        min-height:320px;
    }
    &--fixed-height-small {
        min-height:250px;
    }
    &__tag {
        font-size: 1rem;
        // color:$bright-blue;
        text-transform: uppercase;
        display: block;
        margin-bottom:0.5rem;
        font-size: 0.9rem;
        opacity: 0.7;
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: .5rem;
        vertical-align: text-bottom;
        margin-left: 0.5rem;
        font-weight: 400;
    }
    &__title {
        font-family: $body-font;
        font-size:1.2rem;
        line-height: 1.5rem;
    }
    &__link {
        font-weight:500;
        color:$action-color;    
    }
    &__img {
        max-width:64px;
        margin-bottom:1rem;
        width:64px;
        height:64px;
        &--small {
            max-width:24px;
            width:24px;
            height:24px;
        }
    }
}

.m-color-block:hover {
    cursor: pointer;
}
