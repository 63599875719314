


/*// main grid

.o-footer-grid {
    padding-top: 3.75rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    
    color:white;
    margin-left: 1rem;
    margin-right: 1rem;
    
    display: grid;
    grid-auto-flow: row;
    grid-gap: 2rem;
     
}

.left-col__copyright {
    display:flex;
    color: #ffffff;
    font-size:.8rem;
    grid-row-start: 11;
}

@media (min-width: 800px) {
    .footer-grid {
        display: grid;
        grid-template-columns: 1fr 10rem repeat(5, 1fr);
        grid-template-rows: 75px 1fr 75px 100px 50px;
        grid-gap: 1rem;
        align-items: start;
    }
    .left-col__logo {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    
    .left-col__address {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .left-col__follow {
        grid-column: 1 / 2;
        grid-row: 3/4;
    }
    
    .left-col__social {
        display: flex;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    
    
    .left-col__copyright {
        display:flex;
        flex-wrap:nowrap;
        color: #ffffff;
        white-space: pre;
        font-size:.8rem;
        grid-column: 1 / 3;
        grid-row: 5 / -1;
    }

    .col__label {
        grid-row: 1 / 2;
        grid-column: 3 / 4;

    }
    .col__search {
        grid-row: 1 / 2;
        grid-column: 4 / -1;
        //margin-left: 2rem;
        
    }
    
    .col__services{
        grid-column: 3 /4;
        grid-row: 2 / -1;
    }

    .col__neuro {
        grid-column: 4 / 5;
        grid-row: 2 / -1;
    }
    
    .col__industries {
        grid-column: 5 / 6;
        grid-row: 2 / -1;
    
    }
    
    .col__resources {
        grid-column: 6 / 7;
        grid-row: 2 / -1;
        
    }
    
    .col__about-us {
    
        grid-column: 7 / 8;
        grid-row: 2 / -1;
    }

    .footer__link-list.extraRow__space {
        margin-bottom: 2rem;
    }
 
}
    
    

// nested left side grid for logo, address etc.


// search bar changes
.m-footer__input  {
    background-color: #002A5D;
    color: #ffffff;
    border: none;
}
/*.m-footer__input m-site-search-form__input {
    width: 20%;
}

.m-footer__input[value^="Search"] {
    color:#ffffff;
    font-size: 1rem;
    font-weight: 300;
}

.m-footer__submit-btn {
    background-color: #0066B4;
    position: absolute;
        right:1px;
        top:3px;
        background-image: none;
        border:none;
        position: absolute;
        padding-top: 4px;
        padding-right: 10px;
        padding-left: 10px;
        
}


.m-footer-search-form__submit-btn-img {
    height: 30px!important;
    width: 30px !important;
}

// address changes
.a-address {
    margin-bottom: 0;
}

.telephone {
    margin-top: 10px;
}

//social row changes--is this working?
.m-footer-social-row__link {
    flex-basis:32px;
    margin-top: 2rem;
    margin-right: .5rem;
}

.m-footer-social-row__icon {
    width: 16px;
    height: 16px;
}

.logo__svg > svg {
    height: 40px;
}
original css some changes to button & heading colors and added sub-heading
*/
.footer
{
    background-color: $background-blue;
    padding-bottom: 1rem;
    
    color:white;
    
    @media (max-width:993px)
    {
        padding-top:1rem;
    }
    &__bg-img {
        height:105px;
        // background-image:url('../images/footer-top-white.png');
        background-position-y: bottom;
        background-position-x: center;
        background-size:cover;
        background-repeat: no-repeat;
    }
    &__button {
        background-color: #002A5D;
        border: 2px solid #ffffff;
        margin: 20px 0 20px;
    }
    &__wrapper {
        background-color: $background-blue;
        margin-left: 3rem;
        margin-right: 3rem;
    }
    &__container {
        background-color: $background-blue;
        
    }
    
    &__heading
    {
        font-size:1.125rem;
        font-weight:700;
        font-family: $header-font;
        color: #9DD9EB;
    }
    &__heading-sub {
        font-size:1rem;
        font-weight:700;
        font-family: $header-font;
        z-index: 5;
        color:white;
        border-bottom: solid 1px rgba(255,255,255,0);
        transition: all 0.3s ease-out;
        margin-bottom: 1rem;
    }

    &__heading-sub:hover{
        color: $light-gray;
        color:white !important;
        border-bottom: solid 1px rgba(255,255,255,0.7)
    }
    &__link-list
    {
        margin-top:.5rem;
        margin-bottom:.5rem;
        margin-left:0;
        padding-left:0;
        list-style: none;
    }
    &__link-list-item
    {
        font-size: 1rem;
        margin-left:0;
        padding-left:0;
        margin-top:.25rem;
        margin-bottom:.25rem;
        @media (max-width:993px)
        {
            margin-bottom:.75rem;
        }
    }
    &__link-list-link
    {
        color:$light-gray;
    }
    &__link-list-link:hover
    {
        color:white !important;
        text-decoration: underline;
    }
    &__copyright
    {
        color: #ffffff;
        font-size:.8rem;
        
    }
    
    &__contrast-toggle
    {
        color:white;
        display: block;
        font-weight:600;
        
    }
    &__textarea {
        width:100%;
        border-radius:0px;
        background-color: rgba(255,255,255,0.1);
        border:none;
        padding:1rem;
        color:white;
    }
    &__textarea::placeholder {
        font-weight:300;
        color: rgba(255,255,255,0.6);
        font-size:14px;
    }
    &__logo-svg {
        fill:rgba(255,255,255,1);
        width:64px;
    }
}

.footer__heading-sub:hover {
    color:white !important;
    border-bottom: solid 1px rgba(255,255,255,0.7);
}