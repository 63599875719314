.o-split-page {
    display: flex;
    flex-direction: row;
    @media (max-width:993px) {
        flex-direction: column;
    }
    &__half {
        flex-basis: 50%;
        @media (max-width:993px) {
            flex-basis: auto;
        }
        padding: 3rem 3rem;
        &--blue {
            background-color:$lightest-blue;
        }
    }
    &__content {
        max-width:600px;
        
    }
    &__content:first-child {
        margin-left:auto;
    }
    &__content:last-child {
        margin-right:auto;
    }
}