.m-filter-stack {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // background-color: $lightest-gray;
    // padding:1rem 1rem;
    // border-radius:6px;
    @media (max-width: 1200px) {
        // justify-content: flex-start;
    }
    &__item {
        background-color: $lightest-gray;
        color:$dark-gray;
        margin-bottom:0.5rem;
        padding:1rem 1.5rem;
        border-radius: 6px;
        &--active {
            background-color:$light-gray;
            font-weight:500;
        }
    }
    &__count {
        background-color: $bright-blue;
        color:white;
        font-weight:500;
        border-radius: 100px;
        font-size:0.9rem;
        padding:0.1rem 0.5rem;
    }
    width:100%;
    overflow-x: scroll;
    overflow-y: hidden;
}