.m-success-story-slide
{
    @include shadowed;
    @extend .col-10;
    @extend .offset-1;
    background-color:white;
    border:solid 1px $light-gray;
    padding-top:3em;
    padding-bottom:1rem;
    &__title
    {
        font-size:2rem;
        text-align: center;
        font-family: $body-font;
        font-weight:300;
    }
    &__subtitle
    {
        font-size:1rem;
        text-align: center;
        display: block;
        font-family: $body-font;
        font-weight:600;
        color:$dark-gray;
        margin-bottom:1rem;
    }
    &__text
    {
        text-align: center;
    }
    &__stat-row
    {
        @extend .row;
        display:flex;
        flex-direction: row;
        margin-top:4rem;
        margin-bottom:2rem;
        @media (max-width: 993px) { 
            flex-direction: column;
         }
         .m-stat
         {
            @media (max-width: 993px) { 
                margin-bottom:3rem;
                flex-basis: auto;             
            }
             
         }
    }

}