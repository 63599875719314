.m-image-block {
    background-color:$lightest-gray;
    border-radius:10px;
    background-position: center center;
    background-size:cover;
    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding:10rem 2rem 1rem 2rem;
        height:100%;
        background: linear-gradient(180deg, rgba(32,32,32,0) 40%, rgba(32,32,32,0.4) 80%, rgba(32,32,32,0.7591680617559523) 100%);
        border-radius: 10px;
    }
    &__title {
        font-family: $body-font;
        font-size:1.5rem;
        line-height: 2.1rem;
        color:white;
    }
    &__text {
        color:white;
    }
    &__link {
        font-weight:500;
        color:white;    
    }
    &__img {
        max-width:64px;
        margin-bottom:1.5rem;
    }
}

.m-image-block:hover {
    cursor: pointer;
}