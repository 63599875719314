.m-callout {
    display: flex;
    align-items: center;


    border-radius: 6px;

    &__icon {
        height:32px;
        width:32px;
        min-width: 32px;
        flex-basis:32px;
        margin-right:1rem;
        fill:$orange;
    }
    &__title {
        @include heading-6;
        margin-bottom: 0;
    }
    &__content {
        margin-right:1rem;
        align-content: center;
    }
    &__text {
        margin-bottom: 0;
    }
    &__action {
        margin-left:auto;
    }
    &--shaded {
        background-color: $lightest-gray;
        padding:1rem 1rem;
    }
    &--info {
        background-color: lighten($bright-blue, 53%);
    }
    &--important {
        background-color: #ffeab5;
        background-image:url('../images/important.png');
    }
    &--right-aligned {
        justify-content: flex-end;
    }
    &--condensed {
        .m-callout__action {
            margin-left:0rem;
        }
    }
}