.m-author-highlight
{
    display:flex;
    max-width:400px;
    text-align: left;
    margin-top: 1rem;
    &__photo
    {
       // @extend .img-fluid;
        display: block;
        object-fit: cover;
        border-radius:60px; 
        max-width: 60px;
        margin-left:auto;
        margin-right:auto;
    }
    &__name
    {
        font-weight:500;
        margin-top:1rem;
        margin-bottom: 0rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }
    &__title
    {
        font-size:.8rem;
        color: $medium-gray;
        margin-bottom:.1rem;
        justify-content: center;
    }
    &__bio
    {
        font-size:1rem;
        text-align: left;
        justify-content: center;
        
    }
    &__social-row
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1rem;
    }
    &__social-row-icon
    {
        height:14px;
        width:14px;
        margin:.5rem;
        opacity:0.6;
        transition: all ease-in .2s;
    }
    &__social-row-icon:hover
    {
        opacity: 1; 
    }
}

.m-author-bio{
    display: grid;
    grid-template-columns: repeat(2, minmax(4rem, max-content));
    grid-template-rows:repeat(2, minmax(2rem, max-content));
    row-gap:.25rem;
    column-gap: 1rem;
   
    //padding-left: 2rem;
    
    //max-width:450px;
    text-align: left;
    margin-top: 1rem;

    &__bio
    {
        grid-column: 2/3;
        grid-row: 2/3;
        font-size:1rem;
        text-align: left;
        //margin-left:.5rem;
        margin-top: 0;
        margin-bottom: 0;
        //align-self:center;
        //white-space: nowrap;
        align-self:start;
        line-height: 1;
        
        
    }
    &__name
    {
        grid-column: 2/3;
        grid-row: 1/2;
        font-weight:500;
        margin-top:0;
        margin-bottom: 0;
        margin-right: 1rem;
        //margin-left:.5rem;
        font-weight: $bold;
        //margin-left: 2rem;
        align-self: end;
        line-height: 1;
    }
    &__photo
    {
       // @extend .img-fluid;
        grid-column: 1/ 2;
        grid-row: 1 / -1;
        display: block;
        object-fit: cover;
        border-radius:60px; 
        max-width: 60px;
        margin-left:auto;
        margin-right:auto;
        object-fit: cover;
        align-self: center;
    }

}

.m-author-bio__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(1fr, min-content));
    
}