.m-paging-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    
    &__prev {
        background-color:$lightest-gray;
        flex-basis:64px;
        width:64px;
        text-align: center;
        padding:1rem;
        border-radius: 6px;
    }
    &__prev-img {
        transform:rotate(270deg);
        width:32px;
        height: auto;
        
    }
    &__next {
        background-color:$lightest-gray;
        flex-basis:64px;
        width:64px;
        text-align: center;
        border-radius: 6px;
    }
    &__next-img {
        transform:rotate(90deg);
        width:32px;
        height: auto;
    }
}