.m-image-card
{
    @extend .card;
    @include shadowed;
    border-radius:5px;
    border:none;
    position: relative;
    &__image {
        object-fit: cover;
        height:300px;
        border-radius: 5px;
    }
    &__link {
        color:white;
        display: inline-block;
        padding:.5rem 1.5rem;
        background-color:rgba(255,255,255,0.3);
        border-radius:100px;
        font-size:1rem;
        font-weight:500;
        transition: all .3s ease-out;
    }
    &__link:hover {
        font-weight:500;
        color:white;
        background-color:rgba(255,255,255,0.4);
    }
    &__overlay {
        position: absolute;
        display: none;
        height:100%;
        width:100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // top:0;
        // left:0;
        // display: absolute;
        background-color: rgba(0,0,0,0.5);
        opacity:0%;
        transition: all .3s ease-out;
    }
    &__overlay:hover {
        opacity:100%;
    }
}