.a-select-input
{
    padding:.75rem 4rem .75rem .75rem;
    font-weight:300;
    border-radius:0px;
    border:solid 1px $light-gray;
    transition: all .3s ease-out;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    background-image:url('../images/dropdown-arrow.png');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 95% center;
    &__option
    {
        color:$dark-gray;
        font-weight:300;
    }
}

.a-select-input:focus
{
    border:solid 1px $eyebrow;
    outline:0;
}
