.m-chart-card {
    background-color:$lightest-gray;
    padding:1rem 2rem 2rem 1rem;
    border-radius:10px;
    &__chart {
        margin-top:2rem;
    }
    &__title {
        font-family: $header-font;
        font-size: 1.5rem;
        text-align: center;
        margin: 1rem 1rem .25rem 1rem;
    }
    &__subtitle {
        font-size:1rem;
        text-align: center;
        margin-top:0;
        margin-bottom: 2rem;
        color:$medium-gray;
    }
}

