.o-split-content
{
    @extend .row;
    &__col
    {
        @extend .col-md-6;
        display:flex;
        flex-direction:column;
        justify-content: center;
    }
    &__col--vert-centered
    {
        
    }
    &__col--img-col
    {
        @media (max-width: 767px) { 
            order:-1;        
        }
        display: block !important;
        
    }
    &__col--img
    {
        width:100%;
        // height:100%;
        margin-right:auto;
        margin-left:auto;
        display: block;
        @media (min-width: 1000px) { 
            width:80%;      
        }
        @media (min-width: 1200px) { 
            width:90%;      
        }
        @media (max-width: 767px) {
            margin-bottom:2rem;
        }
    }
    &__col--img-rounded {
        border-radius:20px;
    }
    &__col--small-img
    {
        max-width:128px;
        // height:100%;
        margin-right:auto;
        margin-left:auto;
        display: block;
        @media (min-width: 1000px) { 
            width:80%;      
        }
        @media (min-width: 1200px) { 
            width:90%;      
        }
    }
    &__col--small-medium-img
    {
        max-width:256px;
        // height:100%;
        margin-right:auto;
        margin-left:auto;
        display: block;
        @media (min-width: 1000px) { 
            width:80%;      
        }
        @media (min-width: 1200px) { 
            width:90%;      
        }
    }
}