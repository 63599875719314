.m-format-toggle {
    display: flex;
    justify-content: flex-end;
    &__option {
        margin-right:0.1rem;
        padding:4px 8px;
        &--active {
            background-color:rgba(0,0,0,0.1);
            font-weight:700;
        }
    }

}