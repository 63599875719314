.o-side-panel {
    padding: 1.5rem 1rem 1rem 1rem;
    height:calc(100vh - 80px);
    width:100%;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 80px;
    -webkit-box-shadow: -6px 0px 7px -2px rgba(0,0,0,0.13); 
box-shadow: -3px 0px 7px -2px rgba(0,0,0,0.13);
overflow-y: scroll;
    

}