.m-follow-block {
    background-color: $lightest-blue;
    padding: 1.5rem 1rem 1rem;
    border-radius: 15px;
    position: relative;
    &__title {
        font-size:1.5rem;
    }
    &__text {
        z-index:2;
    }
    &__button {
        transition: all .3s ease-in;
        transition-delay: 300ms;
    }
    &__unfollow-link {
        margin-left:.5rem;
        opacity: 0;
        transition: all .3s ease-in;
        transition-delay: 0ms;
    }
    &__moving-img {
        position: absolute;
        bottom:16px;
        left:50%;
        width:48px;
        height:auto;
        z-index: 1;
        transition: all 1s cubic-bezier(.83,.01,.98,.26);
    }
    &__static-img {
        position: absolute;
        &--1 {
            bottom:32px;
            left:70%;
            width:32px;
            height:auto;
        }
        &--2 {
            bottom:48px;
            right:10%;
            width:20px;
            height:auto;
        }

        &--3 {
            top:16px;
            right:15%;
            width:26px;
            height:auto;
        }
        z-index: 1;
    }
}

.m-follow-block--followed {
    .m-follow-block__moving-img {
        transform: translateX(500px) translateY(-500px);
        width:48px;
        transition: all 1s cubic-bezier(.83,.01,.98,.26);
    }
    .m-follow-block__button {
        background-color:$green;
        border-color: $green;
        transition-delay: 300ms;
        pointer-events: none;
    }
    .m-follow-block__button::before{
        content: "\2714  ";
        color:white;
        font-family: Arial, Helvetica, sans-serif;
    }
    .m-follow-block__unfollow-link {
        opacity: 1;
        transition-delay: 1000ms;
    }
}