.m-resource-list {
    display: flex;
    flex-direction: column;
    padding:0 0rem;
    &__resource {
        margin-bottom:.75rem;
    }
    &__highlight:last-of-type {
        margin-bottom:0;
    }
}