.m-featured-article
{
    background-position: 50% 50%;
    background-size:cover;
    padding-top:10rem;
    position: relative;
    &--tall
    {
        padding-top:12rem;
    }
    &--video
    {
        padding-top:56.25%;
    }
    &__video {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
    }
    &__meta-block
    {
        background-color:$dark-gray;
        padding: 2rem 2rem 2rem 2rem;;
        margin-top:8rem;
        color:#ffffff;
        margin-left:10%;
        margin-right:40%;
        position: relative;
        bottom:-5rem;
        text-align:left;
        @media (max-width:993px)
        {
            margin-left:5%;
            margin-right:5%;
            text-align: center;
            padding: 2rem 1rem 2rem 1rem;
        }
        &--light-blue
        {
            color:$dark-blue;
            background-color:$lightest-blue;
            @if $high-contrast == true {
                background-color:$dark-gray !important;
            }
        }
        &--teal
        {
            color:white;
            background-color:$green;
            @if $high-contrast == true {
                background-color:$dark-gray !important;
            }
        }
        &--right-aligned
        {
            margin-left:40%;
            margin-right:10%;
        }
        &--wide
        {
            margin-left:5%;
            margin-right:20%;
            bottom:-4rem;
            @media (max-width:$wide-breakpoint-min)
            { 
                margin-bottom:6rem;
                margin-left:5%;
                margin-right:5%;
            }
        }
        &--wide-video
        {
            margin-left:5%;
            margin-right:20%;
            bottom:-4rem;
            position:absolute;
            width:70%;
            @media (max-width:$wide-breakpoint-min)
            { 
                left:15px;
                right:15px;
                margin-right:0;
                margin-left:0;
                width:auto;
            }
        }
    }
    &__title
    {
        font-size:2.5rem;
        font-family:$header-font;
        font-weight:900;
        @media (max-width:993px)
        {
            font-size:2.25rem;
        }
    }
    &__tag
    {
        color:rgba(255,255,255,0.7);
        text-transform: uppercase;
        margin-bottom:2rem;
        display: block;
    }
    &__link
    {
        margin-top:1rem;
        display: inline-block;
        color:white;
        padding: .5rem 2rem;
        border:solid 1px white;
        border-radius:100px;
        &--light-blue
        {
            color:$dark-blue;
            border:solid 1px $dark-blue;
        }
        &--teal
        {
            color:white;
            border-color:white;
        }
    }
}