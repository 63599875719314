// @import "../../node_modules/swiper/swiper-bundle.min.css";
// @import '../../node_modules/tippy.js/dist/tippy.css';
@import
    // "../../../../node_modules/bootstrap/scss/bootstrap",
    "bootstrap",
    "helpers/variables",
    "vendor/tippy",
    "vendor/tippy-shift-away",
    "helpers/mixins",
    "helpers/global",
    "helpers/layout",
    "helpers/squiz",
    "components/typography",
    // "components/topnav",
    "atoms/a-button",
    "atoms/a-avatar",
    "atoms/a-badge",
    "atoms/a-callout",
    "atoms/a-collapse-toggle",
    "atoms/a-read-only-value",
    "atoms/a-label",
    "atoms/a-eyebrow",
    "atoms/a-body-image",
    "atoms/a-icon-link",
    "atoms/a-text-box",
    "atoms/a-select-menu",
    "atoms/a-text-input",
    "atoms/a-textarea-input",
    "atoms/a-select-input",
    "atoms/a-ams-img",
    "atoms/a-responsive-image",
    "atoms/a-heading",
    "atoms/a-chip",
    "atoms/a-delta",
    "atoms/a-excerpt",
    "atoms/a-filter-item",
    "atoms/a-icon-button",
    "atoms/a-resource-headline",
    "atoms/a-checkbox",
    "atoms/a-radio-btn",
    "atoms/a-logo",
    "atoms/a-icon",
    "atoms/a-search-input",
    "molecules/m-action-menu",
    "molecules/m-loader",
    "molecules/m-link-list",
    "molecules/m-toc",
    "molecules/m-bulk-action-menu",
    "molecules/m-tree",
    "molecules/m-person-highlight",
    "molecules/m-section-heading",
    "molecules/m-format-toggle",
    "molecules/m-video-card",
    "molecules/m-post-meta",
    "molecules/m-image-card",
    "molecules/m-resource-list",
    "molecules/m-card-v2",
    "molecules/m-follow-block",
    "molecules/m-timeline",
    "molecules/m-insight-card",
    "molecules/m-cta-card",
    "molecules/m-success-story-slide",
    "molecules/m-page-header",
    "molecules/m-pagination-row",
    "molecules/m-author-highlight",
    "molecules/m-stat",
    "molecules/m-paging-row",
    "molecules/m-social-row",
    "molecules/m-cta-block",
    "molecules/m-checklist",
    "molecules/m-details-block",
    "molecules/m-pull-quote",
    "molecules/m-associate-profile-photo",
    "molecules/m-job-list-item",
    "molecules/m-featured-article",
    "molecules/m-article-highlight",
    "molecules/m-form-group",
    "molecules/m-progress-bar",
    "molecules/m-site-search-form",
    "molecules/m-responsive-video",
    "molecules/m-primary-feature-block",
    "molecules/m-secondary-feature-block",
    "molecules/m-illustration-card",
    "molecules/m-photo-overlay-card",
    "molecules/m-featured-cta-card",
    "molecules/m-color-block",
    "molecules/m-image-block",
    "molecules/m-heading-row",
    "molecules/m-date-time",
    "molecules/m-full-width-hero",
    "molecules/m-filter-row",
    "molecules/m-resource-card",
    "molecules/m-breadcrumbs",
    "molecules/m-file-download-card",
    "molecules/m-social-stat",
    "molecules/m-announcement",
    "molecules/m-button-row",
    "molecules/m-image-highlight",
    "molecules/m-registered-block",
    "molecules/m-color-hero-slide",
    "molecules/m-photo-hero-slide",
    "molecules/m-chart-card",
    "molecules/m-tab-row",
    "molecules/m-social-post-card",
    "molecules/m-update-card",
    "molecules/m-event-item",
    "molecules/m-resource-highlight",
    "molecules/m-resource-highlight-v2",
    "molecules/m-byline",
    "molecules/m-share-block",
    "molecules/m-filter-stack",
    "molecules/m-notification-bar",
    "molecules/m-testimonial-block",
    "molecules/m-icon-row",
    "molecules/m-subject-highlight",
    "organisms/footer",
    "organisms/o-card",
    "organisms/o-card-row",
    "organisms/o-side-panel",
    "organisms/o-match-result",
    "organisms/o-collapsible-panel",
    "organisms/o-resource-row",
    "organisms/o-resource-column",
    "organisms/o-event-list",
    "organisms/o-swot",
    "organisms/o-tldr",
    "organisms/o-app-launcher",
    "organisms/o-cta-card-row",
    "organisms/o-details-block-row",
    "organisms/o-short-hero",
    "organisms/o-full-hero",
    "organisms/o-page-nav",
    "organisms/o-table",
    "organisms/o-form-card",
    "organisms/o-full-bleed-slider",
    "organisms/o-author-row",
    "organisms/o-illustration-hero",
    "organisms/o-card-hero",
    "organisms/o-form",
    "organisms/o-filter-manager",
    "organisms/o-success-story-row",
    "organisms/o-page-section",
    "organisms/o-person-grid",
    "organisms/o-split-content",
    "organisms/o-logo-cloud",
    "organisms/o-search-result",
    "organisms/o-event-result",
    "organisms/o-job-search-block",
    "organisms/o-insight-card-container",
    "organisms/o-details-block-section",
    "organisms/o-home-collection",
    "organisms/o-split-page",
    "organisms/o-split-hero",
    "organisms/o-video-hero",
    "organisms/o-top-menu",
    "organisms/o-filter",
    "organisms/o-card-list",
    "organisms/o-block-slider",
    "organisms/o-masonry-section",
    "organisms/o-featured-video",
    "organisms/o-sidebar",
    "organisms/o-treegrid",
    "organisms/o-block",
    "organisms/o-card-grid",
    "organisms/o-asset-bundle",
    "organisms/o-social-summary",
    "organisms/o-hero-slider",
    "organisms/o-search-hero",
    "organisms/o-shaded-hero",
    "organisms/o-half-hero",
    "organisms/o-link-bar",
    "organisms/o-event-slider",
    "organisms/o-news-collection",
    "organisms/o-modal",
    "organisms/o-drawer",
    "organisms/o-stat-row",
    "organisms/o-podcast-listing",
    "organisms/glide",
    // "organisms/navbar",
    "organisms/o-navbar",
    "organisms/o-footer",
    "organisms/o-flex-table",
    "organisms/o-resource-result",
    "organisms/navbar-servicenow",
    "organisms/o-testimonial-block-row",
    "print/p-page",
    "style",
    "animate",
    "animista";

    // @import "node_modules/@glidejs/glide/src/assets/sass/glide.core";


    a
    {

        color:$action-color;
    }

    a:hover
    {
        text-decoration: none;
    }

    body
    {
        color:$dark-gray;
        font-weight:300;
    }




