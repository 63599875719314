.m-tab-row {
    display: flex;
    justify-content: flex-start;
    border-bottom:solid 2px $light-gray;
    width:100%;
    &__tab-title {
        font-weight:$bold;
        color:$medium-gray;
    }
    &__help {
        display: block;
        color:$medium-gray;
        font-size:0.9rem;
        font-weight:400;
    }
    &__tab {
        flex-basis: 1;
        padding-right:2rem;
        padding-left:0.5rem;
        text-align: left;
        padding-bottom:8px;
        transform: translateY(2px);
        color:$dark-gray;
        margin-left:1rem;
        &--active {
            border-bottom: solid 4px $eyebrow;
            font-weight: $bold;
            .m-tab-row__tab-title {
                color:$dark-gray;
            }
        }
        &__tab:hover {
            border-bottom: solid 4px $eyebrow;
            .m-tab-row__tab-title {
                color:$action-color !important;
            }
        }
    }
    &__tab:first-child {
        margin-left:0;
    }

}