.o-app-launcher {
    // @extend .modal;
    z-index: 1999;

    position: fixed;
    top:0;
    left:0;
    height:0px;
    width:0;
    opacity: 0;
    transition: opacity 0.3s ease-out 0s, height 0s ease-out 0.3s, width 0s ease-out 0.3s;
    background-color: #0A1D33;
    overflow: hidden;
    &__dialog {
        @extend .modal-dialog;
        background-color: white;
        border-radius:0px;
        padding: 0;
        max-width:800px;
        margin-top:10vh;
        border:none;
        z-index:9999;
        // &--wide {
        //     max-width: 800px;
        // }
    }
    &__overlay {
        transition: opacity 0.3s ease-out 0s, height 0s ease-out 0.3s, width 0s ease-out 0.3s;
    }


    &__title {
        @extend .modal-title;
        margin-bottom:0;
        color:white;
        opacity: 0;
    }
    &__container {
        position:relative;
        z-index: 2100;
        margin-top:10vh;
        max-width:600px;
        margin-left:auto;
        margin-right:auto;
        // text-align: center;
    }
    &__close-btn {
        position: absolute;
        top:16px;
        right:24px;
        z-index:2001;
        background-color:transparent;
        border:none;
        padding:0;
    }
    &__close-icon {
        height:32px;
        fill:rgba(255,255,255,0.8);
        display: inline-block;
    }
    &__title {
        font-size:3rem;
        font-weight:400;
        margin-bottom:4rem;
        margin-left:1rem;
    }
    &__app {
        display: flex;
        align-items: center;
        margin-bottom:1.5rem;
        padding:1rem;
        transition: all 0.2s ease-out;
        border-radius:4px;
        opacity: 0;
        background-color:rgba(255,255,255,0);
    }

    &__app-icon {
        width:55px;
        margin-right:16px;
        fill:rgba(255,255,255,0.8);
        transition: fill 0.3s ease-out;
    }
    &__app-title {
        font-size:1.75rem;
        color:rgba(255,255,255,0.8);
        transition: color 0.3s ease-out;
    }
    &__go-icon {
        fill:white;
        height:32px;
        margin-left:auto;
        transform: rotate(180deg);
        opacity:0;
    }
    &__app:hover {
        .o-app-launcher__app-title {
            color:rgba(255,255,255,1);
        }
        .o-app-launcher__app-icon {
            fill:rgba(255,255,255,1);
        }
        .o-app-launcher__go-icon {
            opacity: 1;
        }
        background-color:rgba(255,255,255,0.1);
        transition:all 0.2s ease-out;
    }
    &--shown {

        opacity: 1;
        height:100vh;
        width:100vw;
        z-index:2000;
        position: fixed;
        top:0;
        left:0;
        transition: opacity 0.3s ease-out 0s, height 0s ease-out 0s, width 0s ease-out 0s;
        .o-app-launcher__app {
            background-color:rgba(255,255,255,0);
            transition:all 0.2s ease-out;
        }
        .o-app-launcher__app:hover {
            background-color:rgba(255,255,255,0.1);
            transition:all 0.2s ease-out;
        }
        .o-app-launcher__title {
            opacity:1;
            transition: opacity 0.5s ease-out 0.35s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(1) {
            opacity:1;
            transition: opacity 0.5s ease-out 0.5s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(2) {
            opacity:1;
            transition: opacity 0.5s ease-out 0.65s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(3) {
            opacity:1;
            transition: opacity 0.5s ease-out 0.8s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(4) {
            opacity:1;
            transition: opacity 0.5s ease-out 0.95s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(5) {
            opacity:1;
            transition: opacity 0.5s ease-out 1.1s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(6) {
            opacity:1;
            transition: opacity 0.5s ease-out 1.25s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(7) {
            opacity:1;
            transition: opacity 0.5s ease-out 1.4s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(8) {
            opacity:1;
            transition: opacity 0.5s ease-out 1.55s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(9) {
            opacity:1;
            transition: opacity 0.5s ease-out 1.7s, height 0s ease-out 0s, width 0s ease-out 0s;
        }
        .o-app-launcher__app:nth-child(10) {
            opacity:1;
            transition: opacity 0.5s ease-out 1.85s, height 0s ease-out 0s, width 0s ease-out 0s;
        }

    }
}

body:has(.o-app-launcher--shown) {
    overflow: hidden;
}
