.m-date-time
{
    display: flex;
    flex-direction: row;
    
    &__date {
        margin-right:2rem;
    }
    &__date-label {
        font-size:1.25rem;
        color:$medium-gray;
        line-height: 1;
    }
    &__date-img {
        width:24px;
        height:auto;
        opacity: 0.4;
        margin-right:0.25rem;
        margin-top: -6px;
    }
    &__time-label {
        font-size:1.25rem;
        color:$medium-gray;
        line-height: 1;
    }
    &__time-img {
        width:24px;
        height:auto;
        opacity: 0.4;
        margin-right:0.25rem;
        margin-top: -6px;
    }
    &--small {
        .m-date-time__date-label {
            font-size:1rem;
        }
        .m-date-time__time-label {
            font-size:1rem;
        }
        .m-date-time__date-img {
            width:16px;
            margin-top: -4px;
            margin-right:0.1rem;
        } 
        .m-date-time__time-img {
            width:16px;
            margin-top: -4px;
            margin-right:0.1rem;
        }
        .m-date-time__date {
            margin-right:16px;
        }  
    }
}