.o-author-row
{
    @extend .row;
    &__wrapper
    {
        @extend .col-md-12;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media (max-width:993px)
        {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    &__author-highlight
    {
        margin: 0rem 3rem;
        @media (max-width:993px)
        {
            margin-top:5rem;
            margin-left:.5rem;
            margin-right:.5rem;
        }
    }
    &__author-highlight:first-of-type
    {
        @media (max-width:993px)
        {
            margin-top:0rem;
        }
    }
}