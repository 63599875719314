.o-home-collection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width:1700px;
    margin-left:auto;
    margin-right:auto;
    @media (max-width:$wide-breakpoint-min) {
        flex-direction:column;
    }
    &__primary-feature-wrapper {
        flex-basis:60%;
        @media (max-width:$wide-breakpoint-min) {
            flex-basis: auto;
        }
        // @media (max-width:767px) {
        //     flex-direction: column;
        // }
    }
    &__secondary-feature-wrapper {
        flex-basis:calc(40% - 15px);
        @media (max-width:$wide-breakpoint-min) {
            flex-basis: auto;
        }
        margin-left:15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width:$wide-breakpoint-min) {
            flex-direction:row;
            margin-left:0;
        }
        @media (max-width:767px) {
            flex-direction: column;
        }
    }
    &__secondary-feature-block {
        flex-basis: calc(50% - 7.5px);
        @media (min-width:$wide-breakpoint-min) {
            max-height: calc(50% - 7.5px);
            justify-content: center;
        }
        @media (max-width:$wide-breakpoint-min) {
            justify-content: center;
            margin-top:15px;
        }
        @media (max-width:767px) {
            flex-basis: auto;
        }
        @media only screen and (max-width: $wide-breakpoint-min) and (min-width: 767px) {
            width:calc(50% - 7.5px);
            max-width:calc(50% - 7.5px);
        }
        
    }
}