
.a-checkbox *, .a-checkbox *::before, .a-checkbox *::after {
	box-sizing: content-box !important;
}

.a-checkbox input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}




.a-checkbox__group-heading:first-of-type {
	margin-top:0;
}

.a-checkbox span {
	line-height: 1.54;
	@include body;
	font-family: inherit;
}

.a-checkbox__group-heading {
	font-weight:$bold !important;

	// border-bottom: solid 1px rgba(0,0,0,0.15);
	margin-top:32px;
	width:100%;
}

.a-checkbox__group-heading span {
	font-weight:$bold;
	display: inline-block;
	margin-bottom:4px;
	font-size:1.2rem;
	line-height:1.2
}

.a-checkbox {
	display: table;
	position: relative;
	padding-left: 1.8rem;
	cursor: pointer;
	margin-bottom: .5rem;
	margin-top:0.5rem;
}

.a-checkbox input[type="checkbox"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 1.25rem;
	width: 1.25rem;
	background: rgba(241, 245, 248, 1);
	transition: background 250ms;
	border: 1px solid rgba(184, 194, 204, 1);
	border-radius: 0.125rem;
}

.a-checkbox input[type="radio"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 1.25rem;
	width: 1.25rem;
	background: rgba(241, 245, 248, 1);
	transition: background 250ms;
	border: 1px solid rgba(184, 194, 204, 1);
	border-radius: 2.0rem;
}

.a-checkbox input[type="checkbox"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: .45rem;
	top: .18rem;
	width: .25rem;
	height: .6rem;
	border: solid rgba(255, 255, 255, 1);
	border-width: 0 2px 2px 0;
	transition: background 250ms;
	transform: rotate(45deg);
}

.a-checkbox input[type="radio"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: .25rem;
	top: .25rem;
	width: .75rem;
	height: .75rem;
	border-radius: 2.0rem;
	background: rgba(255, 255, 255, 1);
	transition: background 250ms;
}

.a-checkbox input:disabled ~ .b-input::after {
	border-color: rgba(135, 149, 161, 1);
}

.a-checkbox input:checked ~ .b-input::after {
	display: block;
}

.a-checkbox:hover input ~ .b-input,
.a-checkbox input:focus ~ .b-input {
	background: rgb(231, 238, 243);
}

.a-checkbox input:focus ~ .b-input {
	box-shadow: 0 0 0 2px rgba(52,144,220,0.5);
}

.a-checkbox input:checked ~ .b-input {
	background: $dark-gray;
	border-color: $dark-gray;
}

.a-checkbox input[type="checkbox"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.a-checkbox input[type="radio"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.a-checkbox input[type="radio"]:disabled ~ .b-input::after {
	background: rgba(135, 149, 161, 1);
}

.a-checkbox input:checked:focus ~ .b-input, .a-checkbox:hover input:not([disabled]):checked ~ .b-input {
	background: $dark-gray;
	border-color: $dark-gray;
}

.a-checkbox .b-input::before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 3rem;
	height: 3rem;
	margin-left: -0.85rem;
	margin-top: -0.85rem;
	background: $dark-gray;
	border-radius: 2rem;
	opacity: .6;
	z-index: 99999;
	transform: scale(0);
}

@keyframes b-ripple {
	0% {
		transform: scale(0);
	}

	20% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

@keyframes b-ripple-duplicate {
	0% {
		transform: scale(0);
	}

	30% {
		transform: scale(1);
	}

	60% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

.a-checkbox input + .b-input::before {
	// animation: b-ripple 250ms ease-out;
}

.a-checkbox input:checked + .b-input::before {
	// animation-name: b-ripple-duplicate;
}

.a-checkbox .b-input::before {
	visibility: hidden;
}

.a-checkbox input:focus + .b-input::before {
	visibility: visible;
}

.a-checkbox:first-child .b-input::before {
	visibility: hidden;
}

.a-checkbox--hidden-label {
	display: inline-block;
	height:8px;
	span {
		display: none;
	}
}