.m-full-width-hero {
    height:70vh;
    background-position: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: -40vh;
    background-size: cover;
    z-index:1;
    &--narrow {
        height:50vh;
        margin-bottom:-35vh;
    }
}