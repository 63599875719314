.o-resource-column {
    display: flex;
    flex-direction: column;
    padding:0 0rem;
    &__highlight {
        margin-bottom:1.5rem;
    }
    &__highlight:last-of-type {
        margin-bottom:0;
    }
}