.m-section-heading {
    border-bottom: solid 1px $light-gray;
    position: relative;
    text-align: center;
    margin:2rem 0 1.5rem 0;
    &__inner {
        transform: translate(0px, 43px);
        background-color: white;
        display: inline-block !important;
        padding:1rem;
    }
}