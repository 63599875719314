.o-card
{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    &__wrapper {
        @include shadowed;
        
        border:solid 1px #dedede;
        width:100%;
        position: relative;
    }
    &__marker {
        background-color: $orange;
        @include shadowed;
        height:32px;
        width:32px;
        position: absolute;
        top:-12px;
        left:-12px;
        border: 2px solid $orange;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color:white;
        font-size:20px;
        font-weight:900;
    }
    &__selector {
        padding-left:1rem;
        padding-right:1rem;
    }
    &__head {
        display:flex;
        justify-content: space-between;
        padding:1rem 1rem 0.5rem 1rem;
    }
    &__meta {
        text-align: right;
    }
    &__body {
        padding:0.5rem 1rem 1rem 1rem;
    }
    &__footer {
        padding:1.5rem 1rem 1.5rem 1rem;
        border-top:solid 1px $light-gray;
        margin-top:0.5rem;
    }
    &--selected {
        .o-card__wrapper {
            border:dashed 1px $eyebrow;
            background-color:rgba($eyebrow, 0.075);
            @include shadowed-hard;
        }
    }
}