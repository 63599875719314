.m-resource-highlight-v2 {
    max-width:750px;
    display: block;
    padding:1rem;
    transition: all 0.3s ease-out;
    background:rgba(255,255,255,0);
    &__content {
        padding:0rem 0 0.5rem 0;
        &--padded {
            padding: 1rem;
        }
    }
    &__img-wrapper {
        position: relative;
        margin-bottom:1rem;
    }
    &__img-wrapper::after {
        display: block;
        content: '';
        /* 16:9 aspect ratio */
        padding-bottom: 56.25%;
        }
    &__img {  
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__date  {
        @extend .a-medium-text;
    }
    
    &__tag {
        
       @include eyebrow;
        display:block;
        padding:0 0;
        // margin-bottom:0.5rem;
        &--yellow {
            background-color:#FBAE30;
        }
        &--purple {
            background-color:#6C00D6;
        }
        &--red {
            background-color: #F0705F;
        }
        &--green {
            background-color:#2FAD69;
        }
    }
    &__title {
        @include heading-4;
        // margin-top:1rem !important;
        color:$dark-gray;
    }
    &__excerpt {
        @include body;
        color:$dark-gray;
    }
    
    &__cta {
        color:$dark-gray;
        transition: all 0.3s ease-out;
    }
    
    &--inverted {
        .m-resource-highlight-v2__tag {
            color:rgba(255,255,255,0.7);
        }
        .m-resource-highlight-v2__title {
            color:rgba(255,255,255,1);
        }
    }
    
}

.m-resource-highlight-v2:hover {
    background:rgba(255,255,255,1);
    -webkit-box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);


    .m-resource-highlight-v2__cta {
        color:$action-color;
        font-weight:$bold;
    }
}

.m-resource-highlight-v2--inverted:hover{
    background: $dark-blue;
    -webkit-box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
}