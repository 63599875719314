// TODO: Remove margin from block and build a parent block

.m-article-highlight
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding:1rem;
    flex-wrap: wrap;
    &--featured {
        background-color: $lightest-gray;
        border-radius: 6px;
        padding:1.5rem 1rem;
    }
    @media (max-width:993px)
    {
        flex-direction:column;
    }
    margin-top:3rem;
    &__featured-tag {
        flex-basis: 100%;
        color:$medium-gray;
        font-weight: 500;
        text-transform: uppercase;
    }
    &__title
    {
        @include heading-4;
        color:$dark-gray;
        transition: all .2s ease-out;
        font-family: $header-font;
        font-weight:900;
        margin-top:0;

        @media (max-width:767px)
        {
            font-size:1.75rem;
        }
    }
    &__image-wrapper
    {
        flex-basis:30%;
    }
    &__image
    {
        width:100%;
        height:auto;
        object-fit: cover;
    }
    
    &__details-wrapper
    {
        flex-basis:65%;
        color:$dark-gray;
    }
    &__tag
    {
        color: #009ddc;
        @if $high-contrast == true {
            color:$dark-gray !important;
        }
        padding: .25rem 0rem;
        display: inline-block;
        border-radius: 6px;
        font-weight: 300;
        font-size: 1rem;
        margin-bottom: .5rem;
    }
}

.m-article-highlight:hover .m-article-highlight__title
{
    color:$action-color;
}

.m-article-highlight:first-of-type
{
    margin-top:0;
}
