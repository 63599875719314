.m-image-highlight  {
    height:600px;
    max-height:55vh;
    min-height:500px;
    background: transparent center center no-repeat;
    background-size:cover;
    display: block;
    width:100%;
    transition: all 0.3s ease-out;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-decoration: none;
    padding:2rem 2rem 4rem 2rem;
    color:white;
    @media (max-width:767px){
        height:auto;
        max-height:none;
        min-height:500px;
        padding:4rem 2rem 4rem 2rem;
    }
    &--short {
        height:400px;
        max-height:55vh;
        min-height:300px;
        @media (max-width:993px){
            height:100%;
            max-height:none;
            min-height:300px;
        }
    }
    &__overlay {
        height:100%;
        width:100%;
        position: absolute;
        top:0;
        left:0;
        background: rgb(0,0,0);
        background: linear-gradient(30deg,rgba(0,0,0,.934331) 0,rgba(0,0,0,.667945) 51%,transparent 100%);
        opacity: 0.7;
        z-index:1;
        transition: all 0.3s ease-out;
    }
    &__tag {
        @include eyebrow--white;
        z-index: 5;
        margin-bottom: 0;
    }
    &__title {
        @include heading-1;
        color:white;
        text-decoration: none;
        z-index: 5;
        max-width: 20em;
     }
    &__title--heading-4 {
        @include heading-4;
    }

     &__title--wide {
        @include heading-3;
        max-width:80%;
     }
    &__text {
        @include body;
        z-index: 5;
        max-width:50rem;
        margin-top:1rem;
        margin-bottom: 2rem;
        color:white;
    }
    &__text--body{
        @include body;
    }
     &__button {
        z-index: 5;
     }
     &__link {
        z-index: 5;
        color:white;
        border-bottom: solid 1px rgba(255,255,255,0);
        transition: all 0.3s ease-out;
     }

     &__description {
         display: none;
         color:white;
         z-index: 5;
         text-align: center;
     }
}


.m-image-highlight:hover {
    -webkit-box-shadow: 2px 2px 7px 3px rgba(0,0,0,0.2);
        box-shadow: 0px 2px 7px 3px rgba(0,0,0,0.2);
    .m-image-highlight__overlay {
        background: rgb(0,0,0);
        background: linear-gradient(30deg,rgba(0,0,0,.934331) 0,rgba(0,0,0,.667945) 51%,transparent 100%);
        opacity: 1;
    }
    .m-image-highlight__text {
        color:white !important;
    }
    .m-image-highlight__link {
        color:white !important;
        text-decoration: underline;
    }
}
