.m-associate-profile-photo
{
    background-position: 50% 50%;
    background-size:cover;
    padding-top:8rem;
    position: relative;
    @media (max-width:993px)
    {
        background-position: 5% 50%;
    }
    &--subject-center
    {
        @media (max-width:993px)
        {
            background-position: 50% 50%;
        }
    }
    &--subject-right
    {
        @media (max-width:993px)
        {
            background-position: 95% 50%;
        }
    }


    &__meta-block
    {
        background-color:$dark-gray;
        padding: 4rem 2rem 3rem 2rem;;
        margin-top:8rem;
        color:#ffffff;
        margin-left:5%;
        margin-right:40%;
        position: relative;
        bottom:-5rem;
        text-align:left;
        @media (max-width:993px)
        {
            margin-left:5% !important;
            margin-right:5% !important;
            text-align: center;
            padding: 2rem 1rem 2rem 1rem;
        }
        &--light-blue
        {
            color:$dark-blue;
            background-color:$lightest-blue;
            @if $high-contrast == true {
                background-color:$dark-gray !important;
            }
        }
        &--teal
        {
            color:white;
            background-color:$green;
            @if $high-contrast == true {
                background-color:$dark-gray !important;
            }
        }
        &--purple
        {
            color:white;
            background-color:$purple;
            @if $high-contrast == true {
                background-color:$dark-gray !important;
            }
        }
        &--right-aligned
        {
            margin-left:40%;
            margin-right:5%;
        }
    }
    &__title
    {
        font-size:2.5rem;
        line-height:3.5rem;
        @media (max-width:993px)
        {
            font-size:2.25rem;
        }
        @media (max-width:576px)
        {
            font-size: 1.5rem;
            line-height: 1.4;
        }
    }
}