.o-table {
    // border:solid 1px $light-gray;
    border-radius: 10px;
    td {
        padding:1rem 1rem;
        border-bottom:solid 1px $light-gray;
    }
    th {
        padding: .5rem 1rem;
        // border-bottom:solid 2px darken($bright-blue, 3%);
        // border-top:solid 2px $light-medium-gray;
        border-bottom:solid 2px $light-medium-gray;
        // color: darken($bright-blue, 20%);
        color:$dark-gray;
        // background-color:lighten($bright-blue, 53%) !important;
        // background-color:$lightest-gray !important;
        font-weight:500;
    }
}

.o-table {
    &--striped {
        tr:nth-child(even) {
            background-color: $lightest-gray;
          }
    }
}