.m-progress-bar {
    width:100%;
    margin:1rem 0 0 0;
    border-radius:100px;
    position: relative;
    display:flex;
    &__annotation {
        font-size:14px;
        font-weight:500;
        position: absolute;
        left:8px;
        top:1px;
        line-height: 1;
        z-index:200;
        padding:.25rem .5rem;
        // background-color: rgba(0,0,0,0.3);
        border-radius:100px;
        color:white;
        text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.5);
    }
    &__progress-wrapper {
        // border:solid 1px rgba(255,255,255,0.5);
        width:100%;
        height:24px;
        background: $light-gray;
        // margin:2rem 0;
        border-radius:100px;
        position: relative;
    }
    &__bar {
        position: absolute;
        left:0;
        top:0;
        height:100%;
        // border: solid 2px #2F3438;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        background-color:$eyebrow;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        &--yellow {
            background-color:$yellow;
        }
        &--red {
            background-color:$red;
        }
    }
    &__label {
        margin-top:8px;
        margin-bottom:8px;
        color: rgba(255,255,255,0.5);
        &--active {
            color:white;
        }
    }
}