.m-testimonial-block
{
    &--centered
    {
        text-align: center;
    }
    &__img
    {
        //max-width:128px !important;
        width:40% !important;
        display: block;
        margin-bottom: .5rem;
    }
    &__img--wide
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--32
    {
        max-width:32px !important;
        width:32px !important;
    }
    &__img--x-small
    {
        max-width:48px !important;
        width:48px !important;
    }
    &__img--small
    {
        max-width:64px !important;
        width:64px !important;
    }
    &__img--medium
    {
        max-width:96px !important;
        width:96px !important;
    }
    &__img--large
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--full-width
    {
        max-width:80% !important;
        width:80% !important;
    }
    &__img--centered
    {
        // margin-left:auto;
        // margin-right:auto;
    }
    // &__img--centered
    // {
    //     margin-left:auto;
    //     margin-right:auto;
    // }
    &__title
    {
        @include heading-3;
        
    }

    &__quote {
        @include pull-quote;
    }

    
    &__text
    {
        font-size:1rem;
        padding-right:0.75rem;
        white-space: pre-wrap;
    }
    &__text:last-of-type
    {
        margin-bottom:0.5rem;
    }
    &__link
    {
        // font-weight:500;
        // color:$action-color;
        margin-top:1rem;
        display: inline-block;
    }
    &__subtitle
    {
        color:$eyebrow;
        margin-bottom:0;
        padding:0rem 0rem;
        display: inline-block;
        border-radius:6px;
        font-weight:$bold;
        font-size:1rem;
        &--icon-matched {
            color: $icon-color;
        }
        // margin-bottom:.5rem;
    }
}