.o-card-list {
    display: flex;
    flex-direction: column;
    margin-top:1rem;
    &__header {
        padding:1rem 0 2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__select-toggle {
        // margin-bottom:1.5rem;
    }
    &__card {
        margin-bottom:2rem;
    }
}