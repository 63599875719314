.a-button
{
    @extend .btn;
    color:white;
    background-color:$action-color;
    font-weight:$bold;
    // border-radius: 100px;
    border-radius:0px;
    padding:.6rem 1.5rem;
    border:solid 2px $action-color;
    cursor: pointer;
    &--blue {
        background-color:$bright-blue;
        border:solid 1px $bright-blue;
    }
    &--square {
        border-radius: 0;
    }
    &--outline {
        border: solid 2px $action-color;
        color:$action-color !important;
        background-color: white;
    }
    &--outline:hover {
        background-color:rgba(0,0,0,0.1);
        background-color:darken($action-color, 10%);
        color:white !important;
        border:solid 2px darken($action-color, 10%);
    }
    &--secondary {
        background-color:$light-gray;
        color:$action-color !important;
        border:none;
    }
    &--secondary:hover {
        background-color:rgba(0,0,0,0.1);
        background-color:darken($action-color, 10%);
        color:white !important;
        border:solid 2px darken($action-color, 10%);
    }
    &--external::after {
        content: " ↗";
        font-size:15px;
        font-weight: 600;
        display: inline-block;
        padding-left:.5rem;
        opacity: 0.75;
    }
    &--link {
        border-color: transparent;
        background-color: transparent;
        color:$action-color;
    }

    &--small {
        padding: .25rem .5rem;
    }
    &--full-width {
        width: 100%;
    }
    &--large {
        padding:1rem 2rem;
    }
    &--disabled {
        border-color:$light-gray;
        color:$medium-gray !important;
        background-color:$light-gray;
    }
    &--disabled:hover {
        border-color:$light-gray !important;
        color:$medium-gray !important;
        background-color:transparent !important;
    }
}

.a-button:active, .a-button:hover
{
    // color:$action-color;
    // background-color:white;
    background-color:darken($action-color, 10%);
    // border:solid 2px $action-color;
    border:solid 2px darken($action-color, 10%);
    color:white;
}