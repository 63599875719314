﻿/// <reference path="_variables.scss" />

body
{
    font-family:$font-stack;
    font-size:15px;
}

html {
    font-size:15px;
}

.invertedText
    {
        color:white;
    }

h1,h2,h3
{
    font-family:$header-font;
    font-weight:700;
}

h2
{
    @include heading-2;
    @media (max-width:993px)
    {
        font-size:2rem;
    }
    @media (max-width:767px)
    {
        font-size:1.75rem;
    }
}
h1
{
    // color:$dark-gray;
    @include heading-1;
}

.main-content h2
{
    font-size: 2.25rem;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    line-height: 2.6rem;
    font-weight: $font-heavy;
    font-family:$header-font;
    margin-top:70px;
}

.main-content h2:first-child {
    margin-top:0px;
}

.main-content h3{
    font-size: 150%;
    font-weight:300;
    margin-bottom: 0rem;
    margin-top:2rem;
    padding-bottom: 3px;
    position: relative;
    line-height: 3rem;
    font-family: $body-font;
}

.main-content img
{
    @extend .img-fluid;
}

.main-content strong
{
    font-weight:$bold;
}

.main-content p
{
    line-height: 1.6;
    margin-bottom:1.75rem;
}

.main-content
{
    ul li
    {
        margin:.75rem 0rem;
    }
}

h4,h5,h6
{
    font-family: $font-stack;
}

h4
{
    margin-bottom: 5px;
    font-size: 140%;
    font-weight: 700;
}
.a-body-text{
    @include body;
}

.a-italicized-text {
    font-style: italic;
}

.a-large-text
{
    font-size: 1.75rem;
    line-height:1.35 !important;
    &--muted {
        font-size:1.5rem;
        color:$medium-gray;
    }
}

.a-medium-text
{
    @include subhead;
    line-height:1.35 !important;
}

.a-bold-text
{
    font-weight:$bold;
}

.a-muted-text {
    color: $medium-gray;
}

.a-tag {
    color:$bright-blue;
    text-transform: uppercase;
    font-weight:500;
}

.a-subtle-text
{
    font-size:.8rem;
}
.a-warning-text {
    color:$warning;
    font-weight:$bold;
}
.a-positive-text {
    color:$green;
    font-weight:$bold;
}
.a-accent-text {
    color:$eyebrow;
}
.underline-center, .underline-left:after{
    position: relative;
    padding-bottom:0.5rem;
}
.underline-center:after
{
    @include underline-center;
}

.underline-left:after
{
    @include underline-left;
}

h5
{
    margin-bottom:0rem;
}

dl.statistic-list
{
    dt
    {
        font-size:3rem;
        font-weight:200;
    }
    dd
    {
        font-weight:500;
        opacity:0.7;
        margin-bottom:40px;
    }
}

p {
    @include body;
    white-space: pre-wrap;
}

strong {
    font-weight:$bold;
}
