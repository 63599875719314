.a-icon {
    padding:4px 8px;
    border-radius:100px;
    background-color:rgba(0,113,188,0.0);
    transition: all 0.2s ease-in;

    &__img {
        width:44px;
        height:44px;
        padding:10px;
        text-align: center;
        fill: $action-color;
    }
    &--red {
        .a-icon__img {
            fill: $warning;
        }

    }

}
.a-icon:hover {

    background-color:rgba(0,113,188,0.1);

}

.a-icon--red:hover {

    background-color:rgba(162,25,0,0.1);

}