.m-button-row {
    display: flex;
    justify-content: flex-end;
    width:100%;
    &__button {
        margin-right:16px;
    }
    &__button:last-of-type {
        margin-right:0px;
    }
}