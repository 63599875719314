.m-primary-feature-block {
    @media (min-width:$wide-breakpoint-min) {
        @include aspect-ratio(3,2);
    }
    @media (max-width:$medium-breakpoint-max) {
        @include aspect-ratio(16,9);
    }
    background-color:$light-gray;
    background-size:cover;
    &--meta-blocked {
        margin-bottom:5rem;
    }
    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding:8rem 2rem 2rem 2rem;
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height:100%;
    }
    &__title {
        font-family: $header-font;
        color:white;
    }
    &__text {
        font-size:1.5rem;
        color:white;
        margin-top:2rem;
    }
    &__meta-block
    {
        background-color:$dark-gray;
        padding: 2rem 2rem 2rem 2rem;;
        margin-top:8rem;
        color:#ffffff;
        margin-left:10%;
        margin-right:40%;
        position: relative;
        bottom:-5rem;
        text-align:left;
        @media (max-width:993px)
        {
            margin-left:5%;
            margin-right:5%;
            text-align: center;
            padding: 2rem 1rem 2rem 1rem;
        }
        &--light-blue
        {
            color:$dark-blue;
            background-color:$lightest-blue;
            @if $high-contrast == true {
                background-color:$dark-gray !important;
            }
        }
        &--teal
        {
            color:white;
            background-color:$green;
            @if $high-contrast == true {
                background-color:$dark-gray !important;
            }
        }
        &--right-aligned
        {
            margin-left:40%;
            margin-right:10%;
        }
        &--wide
        {
            margin-left:5%;
            margin-right:20%;
            @media (max-width:$wide-breakpoint-min)
            { 
                margin-bottom:6rem;
                margin-left:5%;
                margin-right:5%;
            }
        }
    }
}