.wmde-markdown {
    /* -webkit-text-size-adjust: inherit; */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    word-wrap: inherit;
    color: inherit;
    background-color: inherit;
}

.pretty-json-container {
    padding: 20px;
}