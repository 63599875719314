.o-block-slider {
    padding-bottom:40px !important;
    &__block {
        margin:0 1rem;
        transition: all 0.2s ease-out;
    }
    &__block:first-of-type {
        margin:0 1rem 0 0;
    }
    &__block:last-of-type {
        margin:0 1rem 0 0;
    }
    &__block:hover {
        -webkit-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.15); 
        box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.15);
        transition: all 0.2s ease-out;
        margin-top:-5px;
        .m-resource-highlight-v2__cta {
            color:$action-color;
            font-weight:$bold;
        }
    }
    &__prev {
        color: $dark-gray !important;
    }
    &__next {
        color: $dark-gray !important;
    }
    .swiper-pagination-bullet {
        background-color:$dark-gray !important;
    }
}



