.m-job-list-item
{
    border-bottom:solid 1px $lightest-gray;
    display:flex;
    justify-content: space-between;
    padding-top:2rem;
    padding-bottom:1rem;
    @media (max-width:993px)
    {
        flex-direction: column;
        padding-top:3rem;
        padding-bottom:2rem; 
    }
    &__title
    {
        font-size:1.5rem;
        font-weight:500;
        font-family:$body-font;
    }
    &__location
    {
        color:$medium-gray;
        @if $high-contrast == true {
            color:$dark-gray;
        }
    }
    &__details-wrapper
    {
        flex-basis:70%
    }
    &__button-wrapper
    {
        flex-basis:20%
    }
}

.m-job-list-item:first-of-type
{
    // border-top:solid 1px $lightest-gray;
    @media (max-width:993px)
    {
        padding-top:0rem;
    }
}

.m-job-list-item:last-of-type
{
    border-bottom:none;
}