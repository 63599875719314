.m-photo-overlay-card {
    padding:3rem 2rem;
    background-position: center top;
    background-repeat: no-repeat;
    background-size:cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__title {
        font-size:1.75rem;
        font-family:$header-font;
        font-weight:900;
        width:90%;
        line-height: 1.3;
        color: white;
        text-shadow: 0px 0px 14px rgba(0,0,0,0.60);
        @media (min-width:2000px) {
            width:70%;
            font-size:2.0rem;
        }
        @media (max-width:767px) {
            width:90%;
        }
    }
    &__excerpt {
        width:60%;
        margin-top:1rem;
        color: white;
        text-shadow: 0px 0px 14px rgba(0,0,0,0.60);
        @media (max-width:767px) {
            width:90%;
        }
    }
    &__link {
        margin-top:2rem;
        font-weight:500;
        color:white;
        display: inline-block;
        text-shadow: 0px 0px 14px rgba(0,0,0,0.60);
    }

}