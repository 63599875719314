.m-registered-block {
    display: flex;
    flex-direction: row;
    background-color: $lightest-gray;
    padding: 0.75rem;
    border-radius: 6px;
    &__img {
        width:32px;
        height:auto;
        flex-basis: 24px;
        object-fit: contain;
        margin-right:0.5rem;
    }
    &__label {  
        margin-bottom: 0;
    }
    &__undo {
        font-weight:600;
        display: block;
    }
}